<main class="cart-container">

  <section class="cart-items">


  </section>


  <section class="cart-details">


  </section>

  <section class="cart-suggestions">


  </section>

</main>
