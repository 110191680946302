import {Component, OnInit} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {UserDataService} from '../../services/userdata/user-data.service'
import {AuthService} from '../../services/Auth/auth.service'
import {Router} from '@angular/router'
import {Store} from '@ngrx/store'
import {AuthStateEnum} from '../../services/Auth/authstate.action'
import {Observable} from 'rxjs/internal/Observable'

@Component({
  selector: 'app-signup-confirmation',
  templateUrl: './signup-confirmation.component.html',
  styleUrls: ['./signup-confirmation.component.scss']
})
export class SignupConfirmationComponent implements OnInit {

  confirmForm: FormGroup
  username: string
  email: string
  code: string

  authState$: Observable<AuthStateEnum>

  constructor(private userService: UserDataService,
              private auth: AuthService,
              private router: Router,
              private store: Store<{ authStatus: AuthStateEnum }>) {


    this.authState$ = store.select('authStatus')
    this.authState$.subscribe(state => {

      if (state === AuthStateEnum.Authenticated) {


      }
    })

    this.username = this.auth.updatedUser.username
    this.email = this.auth.updatedUser.email

    this.auth.authenticated.subscribe( status => {


      if (status === true) {

        console.log('Confirmation Component: authenticated status - authenticated.')
        this.confirmedCode()
      }
    })


  }

  ngOnInit() {
    this.confirmForm = new FormGroup({

      code: new FormControl(null, [Validators.required]),


    });

  }


  async confirm() {

    this.code = this.confirmForm.get('code').value
    const result = await this.auth.confirm(this.username, this.code)

    if (result === 'success') {

      this.confirmedCode()

    } else {


    }


  }

  confirmedCode() {

    this.router.navigate(['/home'])
  }

}
