<main class="checkout-container">

  <section class="payment-methods">


  </section>


  <section class="checkout-details">


  </section>


</main>
