import {Injectable, OnChanges, OnInit} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {HttpClient} from '@angular/common/http';
import {Apollo} from 'apollo-angular';
import {CourseModel} from '../../Models/Course.model';

@Injectable({
  providedIn: 'root'
})
export class CourseService {


  graphApiUrl: string

  constructor(
    private sessionStore: SessionStorageService,
    private localStorage: LocalStorageService,
    private http: HttpClient,
    private apollo: Apollo,
  ) {



  }


  getAllCourses() {




  }



  getCourse(courseId: string) {



  }


  getEnrolledCoursesByUserId(userId: string) {




  }

  getCoursesBySubject(subject: string) {



  }

  searchCourses(searchText: string) {



  }



  createCourse(course: CourseModel){



  }



  editCourse(course: CourseModel){



  }



  deleteCourse(courseId: string) {



  }






}
