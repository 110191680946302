import {Injectable, OnChanges, OnInit} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {AmplifyService} from 'aws-amplify-angular'
import {UserModel} from '../../Models/User.model'
import {Auth, Hub} from 'aws-amplify'
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage'
import {Store} from '@ngrx/store'
import {authStateAction, AuthStateEnum, UserAuthenticated, UserMustConfirm, UserUnauthenticated} from './authstate.action'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Apollo, ApolloBase, gql} from 'apollo-angular'
import jwtDecode from 'jwt-decode'
import {SignupJWTModel} from '../../Models/SignupJWT.model'
import { AccessTokenModel} from '../../Models/AccessToken.model'
import {userStateChangeAction} from '../../actions/user-state-change.action'
import {AuthenticationJWTModel} from '../../Models/AuthenticationJWT.model'
import {LoginDto} from '../../dtos/Login.dto'
import {CookieService} from 'ngx-cookie-service'
import {AuthCookieModel} from '../../Models/AuthCookie.model'



const createUser = gql`
  mutation createUser ($cognitoId: String!, $firstName: String!, $lastName: String!, $email: String, $username: String,
    $displayName: String, $password: String, $country: String, $dateOfBirth: String, $locale: String,
    $confirmed: Boolean){

    createUser(cognitoId: $cognitoId, firstName: $firstName, lastName: $lastName, email: $email,
      username: $username, displayName: $displayName, password: $password,
      country: $country, dateOfBirth: $dateOfBirth, locale: $locale, confirmed: $confirmed) {
      userId,
      firstName,
      lastName,
      email,
      username,
      displayName,
      password,
      country,
      dateOfBirth,
      locale,
      confirmed
    }
  }
`;



const getUser = gql`
  mutation user ($cognitoId: String!, $email: String, $username: String,
    $userId: String){

    user(cognitoId: $cognitoId, userId: $userId, email: $email,
      username: $username) {
      userId,
      firstName,
      lastName,
      profileImgUrl,
      email,
      username,
      displayName,
      country,
      dateOfBirth,
      locale,
      confirmed
    }
  }
`;




@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit, OnChanges {




  httpOptions

  public currentAuthState: AuthStateEnum
  public curentDevState: boolean
  public AuthStatus: Observable<{authStatus: AuthStateEnum}>
  public isAuthenticated = new BehaviorSubject<boolean>(false)

  public isDev = new BehaviorSubject<boolean>(false)

  public authenticated: Observable<boolean> = this.isAuthenticated.asObservable()

  public dev: Observable<boolean> = this.isDev.asObservable()

  cognitoUser

 baseUrl = 'https://somulecoapidev.com'

  public userObject = new BehaviorSubject<UserModel>(null)
  public user: Observable<UserModel> = this.userObject.asObservable()

  public updatedUser: UserModel

  clientToken: string

  confirmed: boolean


  constructor(private amplifyService: AmplifyService,
              private sessionStore: SessionStorageService,
              private localStorage: LocalStorageService,
              private http: HttpClient,
              private cookie: CookieService,
              private apollo: Apollo,
              private store: Store<{ auth: {authStatus: AuthStateEnum}, userState: {user: UserModel} }>) {



    this.updatedUser = new UserModel()

    this.AuthStatus = store.select('auth')

    this.AuthStatus.subscribe(status => {



      this.clientToken = this.localStorage.retrieve('client-token')
      this.currentAuthState = status.authStatus

      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization: this.clientToken,
        })
      };
    })


    this.dev.subscribe(devState => {

        this.curentDevState = devState

        if (this.curentDevState){
          const devUser: UserModel = new UserModel()
          this.updatedUser = this.getDevUser()

          this.store.dispatch(userStateChangeAction({user: this.updatedUser}))
          this.userObject.next(this.updatedUser)

          this.sessionStore.store('user', this.updatedUser)

        }

    })

    this.amplifyService.authStateChange$
      .subscribe(authState => {

        if (authState.state === 'signedIn') {

          this.isAuthenticated.next(true)

          // this.store.dispatch(authStateAction({authStatus: AuthStateEnum.Authenticated, timestamp: `${Date.now}`}))
          this.store.dispatch(new UserAuthenticated())
          console.log('Auth Service: AmplifyService state change - signedIn')




        } else if(authState.state === 'mfaRequired') {

          console.log('Auth Service: AmplifyService state change - mfaRequired')



        } else if(authState.state === 'signedOut') {





        } else {
          console.log('Auth Service: AmplifyService state change - no match - else')
         // this.isAuthenticated.next(false)
        //  this.store.dispatch(authStateAction({ authStatus: AuthStateEnum.Unauthenticated, timestamp: `${Date.now}`}))
        }




      });


    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log('user signed in')
          // this.store.dispatch(authStateAction({authStatus: AuthStateEnum.Authenticated, timestamp: `${Date.now}`}))
          this.store.dispatch(new UserAuthenticated())
          break

        case 'signUp':
          console.log('user signed up')
          // this.store.dispatch(authStateAction({authStatus: AuthStateEnum.Confirm, timestamp: `${Date.now}`}))
          this.store.dispatch(new UserMustConfirm())
          console.log('Auth Service: Hub update - signup')
          break
        case 'signOut':
          console.log('user signed out')
          // this.store.dispatch(authStateAction({authStatus: AuthStateEnum.Unauthenticated, timestamp: `${Date.now}`}))
          this.store.dispatch(new UserUnauthenticated())
          break
        case 'signIn_failure':
          console.log('user sign in failed')
          break
        case 'tokenRefresh':
          console.log('token refresh succeeded')
          break
        case 'tokenRefresh_failure':
          console.log('token refresh failed')
          break
        case 'configured':
          console.log('the Auth module is configured')
      }
    })

  }





  // tslint:disable-next-line:contextual-lifecycle
   ngOnChanges() {


        this.sessionStore.observe('user')
          .subscribe(user => this.userObject.next(user));
   }


  // tslint:disable-next-line:variable-name
  async signUp(username: string, password: string, email: string,
         family_name: string, given_name: string, preferred_username: string,
           // tslint:disable-next-line:align variable-name
            locale: string, bDate: string, primary_language: string, country: string): Promise<string> {



    this.updatedUser.firstName = given_name
    this.updatedUser.lastName = family_name
    this.updatedUser.email = email
    this.updatedUser.password = password
    this.updatedUser.displayName = preferred_username
    this.updatedUser.confirmed = false
    this.updatedUser.language = primary_language

    this.updatedUser.dateOfBirth = new Date(bDate)
    this.updatedUser.locale = locale
    this.updatedUser.country = country
    this.updatedUser.username = username
    this.updatedUser.profileImgUrl = ''
    this.updatedUser.bio = 'test'
    this.updatedUser.gender = 'Male'
    this.updatedUser.phone = '3109995555'




    let response = 'failed'


    await Auth.signUp({
      username: this.updatedUser.username,
      password: this.updatedUser.password,
      attributes: {
        email: this.updatedUser.email,
        given_name: this.updatedUser.firstName,
        family_name: this.updatedUser.lastName,
        birthdate: bDate

      },
      validationData: [] // optional
    })
      .then((data) => {

      this.confirmed = data.userConfirmed



      if (!data.user) {
          this.cognitoUser = null
        } else {
          this.cognitoUser = data.user

          response = 'success'

          this.updatedUser.cognitoId = data.userSub

          console.log(`Auth Service: successful signup.  Updated User ${this.updatedUser}`)

          this.http.post<AccessTokenModel>(this.baseUrl + '/auth/signup', this.updatedUser).subscribe((token) => {

            console.log(`Access token: ${token.accessToken}`)

            console.log(`Decoded access token: ${jwtDecode(token.accessToken)}`)

            const returnedUser: SignupJWTModel  = jwtDecode(token.accessToken)


            this.updatedUser.userId = returnedUser.userId
            this.updatedUser.cartId = returnedUser.cartId
            this.updatedUser.walletId = returnedUser.walletId

            this.updatedUser.learningProfileId = returnedUser.learningProfileId


            this.store.dispatch(userStateChangeAction({user: this.updatedUser}))
            this.userObject.next(this.updatedUser)

            this.sessionStore.store('user', this.updatedUser)

            const AuthenticationCookie = new AuthCookieModel()

            AuthenticationCookie.userId = this.updatedUser.userId
            AuthenticationCookie.username = this.updatedUser.username
            AuthenticationCookie.expirationDays = 7
            AuthenticationCookie.authenticatedDate = new Date()

            this.cookie.set('auth', JSON.stringify(AuthenticationCookie))


          })

          // this.apollo.mutate({
          //   mutation: createUser,
          //   variables: {
          //     cognitoId: this.cognitoUser.id,
          //     firstName: this.updatedUser.firstName,
          //     lastName: this.updatedUser.lastName,
          //     email: this.updatedUser.email,
          //     username: this.updatedUser.userName,
          //     displayName: this.updatedUser.displayName,
          //     dateOfBirth: this.updatedUser.dateOfBirth,
          //     locale: this.updatedUser.locale,
          //     country: this.updatedUser.country,
          //     confirmed: this.updatedUser.emailConfirmed
          //   }
          //   // tslint:disable-next-line:no-shadowed-variable
          // }).subscribe(({ data }) => {
          //
          //   console.log('got data', data)
          // },(error) => {
          //   console.log('there was an error sending the query', error);
          // })


          // this.updatedUser = this.userData.getUserData(this.updatedUser.userName);


          this.userObject.next(this.updatedUser)

          this.sessionStore.store('user', this.updatedUser)

        }


      console.log(data)

      if (this.confirmed === false) {

          console.log('Auth Service: User not confirmed. Calling authstatusaction confirm.')
          // this.store.dispatch(authStateAction({authStatus: AuthStateEnum.Confirm, timestamp: `${Date.now}`}))
          this.store.dispatch(new UserMustConfirm())

        } else {

          console.log('Auth Service: User confirmed.')
        }

      } )
      .catch((err) => {

        console.log(err)
        response = 'failed'

      })

    console.log(`Auth Service: Signup Response: ${response}`)

    return response


  }




  async login(email: string, password: string): Promise<string> {

    let result = 'failed'


    this.updatedUser = new UserModel()





    const loginDTO: LoginDto = new LoginDto()

    loginDTO.username = email
    loginDTO.password = password


    this.http.post<AccessTokenModel>(this.baseUrl + '/auth/login', loginDTO).subscribe((token) => {

      console.log(`Access token: ${token.accessToken}`)

      console.log(`Decoded access token: ${jwtDecode(token.accessToken)}`)

      const returnedUser: AuthenticationJWTModel = jwtDecode(token.accessToken)

      this.updatedUser.cognitoId = returnedUser.cognitoId
      this.updatedUser.userId = returnedUser.userId
      this.updatedUser.cartId = returnedUser.cartId
      this.updatedUser.walletId = returnedUser.walletId
      this.updatedUser.username = returnedUser.username
      this.updatedUser.email = returnedUser.email
      this.updatedUser.displayName = returnedUser.displayName
      this.updatedUser.firstName = returnedUser.firstName
      this.updatedUser.lastName = returnedUser.lastName
      this.updatedUser.confirmed = returnedUser.confirmed
      this.updatedUser.country = returnedUser.country
      this.updatedUser.locale = returnedUser.locale
      this.updatedUser.language = returnedUser.language
      this.updatedUser.profileImgUrl = returnedUser.profileImgUrl
      this.updatedUser.phone = returnedUser.phone
      this.updatedUser.gender = returnedUser.gender
      this.updatedUser.dateOfBirth = returnedUser.dateOfBirth
      this.updatedUser.signupDate = returnedUser.signupDate
      this.updatedUser.lastUpdate = returnedUser.lastUpdate

      this.updatedUser.learningProfileId = returnedUser.learningProfileId


      this.store.dispatch(userStateChangeAction({user: this.updatedUser}))
      this.userObject.next(this.updatedUser)

      this.sessionStore.store('user', this.updatedUser)

      this.updateAuthentication(true)

      console.log(`Auth Service: successful login.  Updated User ${this.updatedUser}`)

      result = 'success'

    },
    (err) => {



      console.log(`Auth Service: login failed. ${err}`)

    })






    // await Auth.signIn(email, password)
    //   .then(
    //     (user) => {
    //       this.cognitoUser = user
    //       // this.store.dispatch(authStateAction({authStatus: AuthStateEnum.Authenticated, timestamp: `${Date.now}`}))
    //       this.store.dispatch(new UserAuthenticated())
    //       result = 'success'
    //
    //
    //
    //
    //     } )
    //   .catch(
    //     err => console.log(err)
    //   )

    // if (this.cognitoUser) {
    //
    //   this.updateAuthentication(true);
    // }

    return result

  }





  // async login(email: string, password: string): Promise<string> {
  //
  //   let result = 'failed'
  //   await Auth.signIn(email, password)
  //     .then(
  //       (user) => {
  //         this.cognitoUser = user
  //         // this.store.dispatch(authStateAction({authStatus: AuthStateEnum.Authenticated, timestamp: `${Date.now}`}))
  //         this.store.dispatch(new UserAuthenticated())
  //         result = 'success'
  //         this.updatedUser = new UserModel()
  //
  //
  //
  //
  //
  //         const loginDTO: LoginDto = new LoginDto()
  //
  //         loginDTO.username = email
  //         loginDTO.password = password
  //
  //
  //         this.http.post<AccessTokenModel>(this.baseUrl + '/auth/login', loginDTO).subscribe((token) => {
  //
  //           console.log(`Access token: ${token.accessToken}`)
  //
  //           console.log(`Decoded access token: ${jwtDecode(token.accessToken)}`)
  //
  //           const returnedUser: AuthenticationJWTModel = jwtDecode(token.accessToken)
  //
  //           this.updatedUser.cognitoId = returnedUser.cognitoId
  //           this.updatedUser.userId = returnedUser.userId
  //           this.updatedUser.cartId = returnedUser.cartId
  //           this.updatedUser.walletId = returnedUser.walletId
  //           this.updatedUser.username = returnedUser.username
  //           this.updatedUser.email = returnedUser.email
  //           this.updatedUser.displayName = returnedUser.displayName
  //           this.updatedUser.firstName = returnedUser.firstName
  //           this.updatedUser.lastName = returnedUser.lastName
  //           this.updatedUser.confirmed = returnedUser.confirmed
  //           this.updatedUser.country = returnedUser.country
  //           this.updatedUser.locale = returnedUser.locale
  //           this.updatedUser.language = returnedUser.language
  //           this.updatedUser.profileImgUrl = returnedUser.profileImgUrl
  //           this.updatedUser.phone = returnedUser.phone
  //           this.updatedUser.gender = returnedUser.gender
  //           this.updatedUser.dateOfBirth = returnedUser.dateOfBirth
  //           this.updatedUser.signupDate = returnedUser.signupDate
  //           this.updatedUser.lastUpdate = returnedUser.lastUpdate
  //
  //           this.updatedUser.learningProfileId = returnedUser.learningProfileId
  //
  //
  //           this.store.dispatch(userStateChangeAction({user: this.updatedUser}))
  //           this.userObject.next(this.updatedUser)
  //
  //           this.sessionStore.store('user', this.updatedUser)
  //
  //
  //           console.log(`Auth Service: successful login.  Updated User ${this.updatedUser}`)
  //
  //         })
  //
  //
  //
  //
  //
  //       } )
  //     .catch(
  //       err => console.log(err)
  //     )
  //
  //   if (this.cognitoUser) {
  //
  //     this.updateAuthentication(true);
  //   }
  //
  //   return result
  //
  // }


  async logout() {

    try {
      await Auth.signOut()
    } catch (error) {
      console.log('error signing out: ', error)
    }
    this.updateAuthentication(false)
  }


  async confirm(username: string, code: string ): Promise<string>{


    let response = 'failed'
    try {
      await Auth.confirmSignUp(username, code)
      response = 'success'
      this.updatedUser = { ...this.updatedUser, deserialize: this.updatedUser.deserialize}
      this.updatedUser.confirmed = true

      this.store.dispatch(userStateChangeAction({user: this.updatedUser}))
      this.userObject.next(this.updatedUser)

      this.sessionStore.store('user', this.updatedUser)
      this.isAuthenticated.next(true)

    } catch (error) {
      console.log('error confirming sign up', error)
    }

    return response

  }



  updateAuthentication(auth: boolean) {

    this.isAuthenticated.next(auth);

  }

  // tslint:disable-next-line:contextual-lifecycle
  ngOnInit(): void {
  }


  getDevUser(): UserModel{

    const user = new UserModel()


    user.userId = '1'
    user.bio = 'dev user bio'
    user.profileHeadline = 'Dev User Profile Headline'
    user.cartId = '123'
    user.country = 'US'
    user.cognitoId = 'CognitoID1'
    user.confirmed = true
    user.dateOfBirth = new Date('1982-06-21')
    user.cartId = '123'
    user.displayName = 'Dev User'
    user.email = 'dev@somuleco.com'
    user.firstName = 'dev1'
    user.lastName = 'development'
    user.language = 'English'
    user.username = 'devuser'
    user.gender = 'M'
    user.lastUpdate = new Date('2021-11-01')
    user.learningProfileId = '123'
    user.phone = '3109123333'
    user.locale = 'en-US'
    user.profileImgUrl = '../assets/DevImages/Profile.png'



    return  user

  }



}
