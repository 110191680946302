<mat-card class="card" style="justify-content: center;">

  <div class="form-container">

    <div class="fomr-header">
      <mat-label>Signup</mat-label>
    </div>

    <form  [formGroup]="courseCreationForm" (ngSubmit)="onSave()">


      <div class="form-group">


        <mat-form-field appearance="outline" class="form-field title">
          <mat-label>Course Title</mat-label>
          <input matInput formControlName="title" id="title" class="form-control title" placeholder="Enter Course Title" required>
          <!--          <mat-hint>Hint</mat-hint>-->
        </mat-form-field>



        <mat-form-field appearance="outline" class="form-field standard">
          <mat-label>Subject 1</mat-label>
          <input matInput type="text" formControlName="subject1" id="subject1" class="form-control"
                 placeholder="Subject 1" required minlength="3"
          >
<!--          <mat-hint>Requirements: Minimum - 8 characters.</mat-hint>-->
        </mat-form-field>


        <mat-form-field appearance="outline" class="form-field standard">
          <mat-label>Subject 2</mat-label>
          <input matInput type="text" formControlName="subject2" id="subject2" class="form-control"
                 placeholder="Subject 2" required minlength="3"
          >
          <!--          <mat-hint>Requirements: Minimum - 8 characters.</mat-hint>-->
        </mat-form-field>


        <mat-form-field appearance="outline" class="form-field standard">
          <mat-label>Subject 3</mat-label>
          <input matInput type="text" formControlName="subject3" id="subject3" class="form-control"
                 placeholder="Subject 3" required minlength="3"
          >
          <!--          <mat-hint>Requirements: Minimum - 8 characters.</mat-hint>-->
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-field standard">
          <mat-label>Subject 4</mat-label>
          <input matInput type="text" formControlName="subject4" id="subject4" class="form-control"
                 placeholder="Subject 4" required minlength="3"
          >
          <!--          <mat-hint>Requirements: Minimum - 8 characters.</mat-hint>-->
        </mat-form-field>


        <mat-form-field [style.fontSize]="11">
          <mat-label>Description</mat-label>
          <textarea matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    formControlName="description"
                    cdkAutosizeMaxRows="5"></textarea>
        </mat-form-field>




        <mat-form-field appearance="outline" class="form-field standard">
          <mat-label>Language</mat-label>
          <mat-select type="text" formControlName="language" id="language" class="form-control"
                      placeholder="Language" required minlength="8"
          >
            <mat-option *ngFor="let language of languages" [value]="language">{{language}}</mat-option>

          </mat-select>
        </mat-form-field>
        <span *ngIf="!courseCreationForm.get('language').valid && courseCreationForm.touched" class="alert-warning">Please select a Language.</span>



        <button mat-flat-button color="primary" type="submit" class="btn btn-primary button" [disabled]="!courseCreationForm.valid">Submit</button>

      </div>


    </form>



  </div>

</mat-card>
