import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import {authStateReducer} from './authentication.reducer';
import {userStateChangeReducer} from './user-state-change.reducer';

export interface State {

}

export const reducers: ActionReducerMap<State> = {

  auth: authStateReducer,
  userState: userStateChangeReducer,


}


export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
