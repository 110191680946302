import { createAction, props, Action } from '@ngrx/store'

export const USER_AUTHENTICATED = 'USER_AUTHENTICATED'
export const USER_UNAUTHENTICATED = 'USER_UNAUTHENTICATED'
export const USER_MUST_CONFIRM = 'USER_MUST_CONFIRM'

export class UserAuthenticated implements Action{

  readonly type = USER_AUTHENTICATED
  public payload: AuthStateEnum = AuthStateEnum.Authenticated
  public timestamp = `${Date.now}`

  // constructor(public payload: AuthStateEnum = AuthStateEnum.Authenticated, public timestamp: string) {
  // }


}

export class UserUnauthenticated implements Action{

  readonly type = USER_UNAUTHENTICATED
  public payload: AuthStateEnum = AuthStateEnum.Unauthenticated
  public timestamp = `${Date.now}`



}


export class UserMustConfirm implements Action{

  readonly type = USER_MUST_CONFIRM

  public payload: AuthStateEnum = AuthStateEnum.Confirm
  public timestamp = `${Date.now}`


  // constructor(public payload: AuthStateEnum = AuthStateEnum.Confirm, public timestamp: string) {
  // }


}



export const authStateAction = createAction(
  '[Auth Service] AuthState',
  props<{
    authStatus: AuthStateEnum;
    timestamp: string;
  }>()
)


export enum AuthStateEnum {
  Authenticated,
  Unauthenticated,
  Confirm
}
