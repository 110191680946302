import {Deserializable} from './Deserializable.model';

export class UserModel implements Deserializable{

  public userId: string

  public cognitoId: string

  public firstName: string

  public lastName: string

  public username: string

  public displayName: string

  public email: string

  public password: string

  public bio: string

  public dateOfBirth: Date

  public country: string

  public language: string

  public locale: string

  public confirmed: boolean

  public profileImgUrl: string

  public profileHeadline: string

  public gender: string

  public phone: string

  public signupDate: Date

  public lastUpdate: Date

  public walletId: string

  public learningProfileId: string

  public cartId: string



  constructor() {


    // this.firstName = fname
    // this.lastName = lname
    // this.userName = userName
    // this.displayName = displayName
    // this.email = email
    // this.password = password
    // this.country = country
    // this.language = language
    // this.dateOfBirth = dateOfBirth

  }



   deserialize(input: any): this {
    return Object.assign(this, input);
  }




}
