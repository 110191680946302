
const awsconfig = {
  Auth: {
    identityPoolId: 'us-west-2:ac2d7a9b-abb0-4b0c-a0c1-52a1d98c20be', //REQUIRED - Amazon Cognito Identity Pool ID
    region: 'us-west-2', // REQUIRED - Amazon Cognito Region
    userPoolId: 'us-west-2_PO6WYuiAp', //OPTIONAL - Amazon Cognito User Pool ID
    userPoolWebClientId: '4n98u7f27io73g4fj6j19ifgl8', //OPTIONAL - Amazon Cognito Web Client ID
    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: 'somuleco.com',
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true
    },
  },
  Storage: {
    AWSS3: {
      bucket: 'somuleco-profile-images-dev', //REQUIRED -  Amazon S3 bucket
      region: 'us-west-2', //OPTIONAL -  Amazon service region
    }
  },
  API: {
    "aws_appsync_graphqlEndpoint": "https://uy7j3ost6nfshfm556ce4qhcaa.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-nx6lwxfghff3zd23r2dtccycyu",
  }
};


export default awsconfig;
