import {Component, OnDestroy, OnInit} from '@angular/core'
import {AuthService} from '../services/Auth/auth.service'
import {LmsService} from '../services/lms/lms.service'
import {Router} from '@angular/router'
import {Observable} from 'rxjs/internal/Observable'
import {Subject} from 'rxjs/internal/Subject'
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject'
import {MatIconRegistry} from '@angular/material/icon'
import {DomSanitizer} from '@angular/platform-browser'


@Component({
  selector: 'top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.scss']
})
export class TopNavBarComponent implements OnInit, OnDestroy {

  authSubject$
  authenticated
  lms: boolean
  username: string

  constructor(private auth: AuthService,
              private lmsServ: LmsService,
              private matIconReg: MatIconRegistry,
              private domSanitizer: DomSanitizer,
              private router: Router) {


    this.username = 'test'


  }

  ngOnInit() {

    this.auth.authenticated
      .subscribe(
        (status) => {

          this.authenticated = status

        }
      )



    /*this.authSubject$ = new BehaviorSubject(this.auth.authenticated)
    this.authSubject$.subscribe(x => {this.authenticated = x} )
    this.authSubject$.next(this.auth.authenticated)*/

    this.matIconReg.addSvgIcon('chat', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/TopNavIcons/Chat.svg'))
    this.matIconReg.addSvgIcon('notification', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/TopNavIcons/Notification.svg'))
    this.matIconReg.addSvgIcon('cart', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/TopNavIcons/ShoppingCart.svg'))
    this.matIconReg.addSvgIcon('user', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/TopNavIcons/user.svg'))

  }



 goToLogin(){


    this.router.navigate(['/authentication'])
 }

 goToSomuleco() {

    this.lms = false

    this.lmsServ.updateLMS(false)

    this.router.navigate(['/home'])
 }

 goToLMS() {

    this.lms = true

    this.lmsServ.updateLMS(true)

    this.router.navigate(['/lmsdashboard'])

 }

  goToSiteMap() {


    this.router.navigate(['/sitemap'])

  }

 authenticate(){

    this.auth.isAuthenticated.next(true)
    this.auth.isDev.next(true)
 }

  unAuthenticate(){

    this.auth.isAuthenticated.next(false)
    this.auth.isDev.next(false)
  }

  ngOnDestroy(){

  }

}
