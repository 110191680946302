<main fxLayout="row" fxLayoutAlign="center center" class="sitemap-container">

  <section fxLayout="column" fxLayoutAlign="center center">



    <p>Lesson Feed</p>

    <p>Lesson Post Page</p>

    <p>Create Lesson Post</p>

    <p>Create Social Post</p>

    <p>Social Feed</p>

    <p>Socal Post Page</p>

    <p>Notifications</p>

    <p>Profile Page</p>

    <p>Edit Profile Page</p>

  </section>

  <section fxLayout="column" fxLayoutAlign="center center">




    <p><a routerLink="/course/1">Course Home</a></p>

    <p><a routerLink="/editcourse/1">Edit Course Page</a></p>

    <p><a routerLink="/lesson/1">Lesson Page</a></p>

    <p><a routerLink="/assessment/1">Assessement Page</a></p>

    <p>Create Lesson Page</p>

    <p>Create Assessment Page</p>

  </section>

  <section fxLayout="column" fxLayoutAlign="center center">

    <p>Chat Home Page</p>

    <p>Wallet Page</p>

    <p>Learning Profile Page</p>

    <p>Library</p>

    <p>My Library Page</p>

    <p>Shopping Cart</p>

    <p>Checkout</p>

  </section>


</main>
