<!--
  Desktop Two Column Layout

-->



<main class="profile-container-desktop">

  <section class="profile-info-container left-column">



    <mat-card class="header-card">

      <div fxLayout="column" fxLayoutAlign="space-between">

        <div fxLayout="row" fxLayoutAlign="start start" class="header-container-row">

          <img src="{{profileImg}}" height="100" width="100"   />

          <div fxLayout="column" class="username-container">

            <p>{{displayName}}</p>
            <p>@{{username}}</p>


          </div>




        </div>


        <div>

          {{tagline}}
        </div>

      </div>


    </mat-card>

    <div fxLayout="row" fxLayoutAlign="start start">

      <button mat-raised-button class="follow-button" color="primary">Follow </button>
    </div>

  </section>

  <section class="tab-container center-column">


    <mat-tab-group>

      <mat-tab label="About"></mat-tab>
      <mat-tab label="Social"></mat-tab>
      <mat-tab label="Lessons"></mat-tab>
    </mat-tab-group>


  </section>

  <section class="right-column">


      <p id="rightColumn">Right Column</p>
  </section>

</main>

<!--<main class="profile-container">-->

<!--  <section class="profile-header">-->

<!--    <div class="header-content">-->

<!--      <div class="left-header">-->

<!--        <div class="profile-img-container">-->
<!--          <img src="{{profileImg}}" height="100" width="100"   />-->

<!--          <div class="username-container">-->
<!--            <p>{{displayName}}</p>-->
<!--            <p>@{{username}}</p>-->

<!--          </div>-->

<!--        </div>-->

<!--      </div>-->

<!--      <div class="center-header">-->

<!--        <div class="user-info">-->
<!--        {{headline}}-->

<!--        </div>-->

<!--      </div>-->

<!--      <div class="right-header">-->
<!--        <button mat-raised-button class="follow-button" color="primary">Follow </button>-->

<!--      </div>-->

<!--    </div>-->

<!--  </section>-->

<!--  <section class="profile-nav">-->


<!--    <div class="nav-container">-->

<!--      <span class="nav-item"> <button mat-button (click)="goToAbout()">About</button></span>-->

<!--      <span class="nav-item"> <button mat-button (click)="goToSocial()">Social</button></span>-->

<!--      <span class="nav-item"> <button mat-button (click)="goToLessons()">Lessons</button></span>-->

<!--    </div>-->

<!--  </section>-->

<!--  <section class="profile-main-content">-->

<!--    <router-outlet></router-outlet>-->

<!--  </section>-->

<!--</main>-->







<!--
  Mobile and Table Layout

-->












<!--<div class="container">-->

<!--  <div class="row">-->

<!--    <div class="col col-md-1">-->
<!--      <img src="{{profileImg}}" alt="Profile Image">-->

<!--    </div>-->



<!--    <div class="col col-md-2">-->

<!--      <div class="col justify-content-start align-content-start">-->

<!--        <p>{{displayName}}</p>-->
<!--        <br>-->
<!--        <p>{{username}}</p>-->


<!--      </div>-->

<!--      <div class="col justify-content-end align-content-start">-->

<!--        <button mat-raised-button>Follow</button>-->

<!--      </div>-->

<!--    </div>-->

<!--    <div class="col col-md-9">-->


<!--    </div>-->
<!--  </div>-->

<!--    <div class="row">-->

<!--      <div class="col">-->
<!--        <button mat-icon-button>-->
<!--            <mat-icon>-->
<!--              List Alt-->
<!--            </mat-icon>-->
<!--        </button>-->

<!--      </div>-->

<!--      <div class="col">-->
<!--        <button mat-icon-button>-->
<!--          <mat-icon>-->
<!--            Favorite-->
<!--          </mat-icon>-->
<!--        </button>-->

<!--      </div>-->

<!--      <div class="col">-->
<!--        <button mat-icon-button>-->
<!--          <mat-icon>-->
<!--            Psychology-->
<!--          </mat-icon>-->
<!--        </button>-->

<!--      </div>-->

<!--      <div class="col">-->
<!--        <button mat-icon-button>-->
<!--          <mat-icon>-->
<!--            Question Answer-->
<!--          </mat-icon>-->
<!--        </button>-->

<!--      </div>-->

<!--      <div class="col">-->
<!--        <button mat-icon-button>-->
<!--          <mat-icon>-->
<!--            Play Lesson-->
<!--          </mat-icon>-->
<!--        </button>-->

<!--      </div>-->

<!--      <div class="col">-->
<!--        <button mat-icon-button>-->
<!--          <mat-icon>-->
<!--            Lightbulb-->
<!--          </mat-icon>-->
<!--        </button>-->

<!--      </div>-->

<!--      <div class="col">-->
<!--        <button mat-icon-button>-->
<!--          <mat-icon>-->
<!--            Groups-->
<!--          </mat-icon>-->
<!--        </button>-->

<!--      </div>-->

<!--    </div>-->

<!--    <div>-->

<!--      <router-outlet></router-outlet>-->
<!--    </div>-->

<!--</div>-->




<!--<div class="container">-->

<!--  <div class="row">-->

<!--    <div class="col col-md-3">-->


<!--    </div>-->





<!--      <div class="col col-md-6">-->





<!--        <div class="row">-->

<!--           <div class="col-3">-->

<!--             <img src="{{profileImg}}" alt="Profile Image">-->

<!--           </div>-->


<!--            <div class="col-9">-->

<!--              <div class="row">-->

<!--                  <div class="col justify-content-start align-content-start">-->

<!--                    <p>{{displayName}}</p>-->
<!--                    <br>-->
<!--                    <p>{{username}}</p>-->


<!--                  </div>-->

<!--                  <div class="col justify-content-end align-content-start">-->

<!--                    <button mat-raised-button>Follow</button>-->

<!--                  </div>-->
<!--              </div>-->
<!--            </div>-->


<!--            <div class="row">-->


<!--            </div>-->

<!--          </div>-->



<!--        <div class="row">-->

<!--          <mat-tab-group>-->
<!--            <mat-tab label="Info">-->
<!--              <h1>Some tab content</h1>-->
<!--              <p>...</p>-->
<!--            </mat-tab>-->
<!--            <mat-tab label="Posts">-->
<!--              <h1>Some more tab content</h1>-->
<!--              <p>...</p>-->
<!--            </mat-tab>-->
<!--            <mat-tab label="Lessons">-->
<!--              <h1>Some more tab content</h1>-->
<!--              <p>...</p>-->
<!--            </mat-tab>-->
<!--            <mat-tab label="Learning">-->
<!--              <h1>Some more tab content</h1>-->
<!--              <p>...</p>-->
<!--            </mat-tab>-->
<!--            <mat-tab label="Activity">-->
<!--              <h1>Some more tab content</h1>-->
<!--              <p>...</p>-->
<!--            </mat-tab>-->
<!--          </mat-tab-group>-->

<!--        </div>-->
<!--      </div>-->



<!--    <div class="col col-md-3">-->


<!--    </div>-->

<!--  </div>-->

<!--</div>-->
