import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'edit-assessment',
  templateUrl: './edit-assessment.component.html',
  styleUrls: ['./edit-assessment.component.scss']
})
export class EditAssessmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
