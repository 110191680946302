<main class="live-container">

  <section class="live-header">


  </section>


  <section class="live-content">
  <router-outlet></router-outlet>

  </section>

</main>
