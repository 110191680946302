import {Deserializable} from '../Models/Deserializable.model'

export class LoginDto implements Deserializable {

  public username: string

  public  email: string

  public  password: string



  deserialize(input: any): this {
    return undefined
  }
}
