<!--The content below is only a placeholder and can be replaced.-->


<top-nav-bar></top-nav-bar>


<side-drawer-nav *ngIf="authenticated && !lms"></side-drawer-nav>

<lms-sidenav *ngIf="authenticated && lms"></lms-sidenav>

<router-outlet></router-outlet>

