import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/Auth/auth.service'
import {UserDataService} from '../../services/userdata/user-data.service'
import {ActivatedRoute, Router} from '@angular/router'
import {Store} from '@ngrx/store'
import {UserModel} from '../../Models/User.model'
import {SocialPostModel} from '../../Models/SocialPost.model'
import {LessonPostModel} from '../../Models/LessonPost.model'
import {CourseEnrollmentModel} from '../../Models/CourseEnrollment.model'
import {Observable, Subscription} from 'rxjs'

@Component({
  selector: 'app-social-feed',
  templateUrl: './social-feed.component.html',
  styleUrls: ['./social-feed.component.scss']
})
export class SocialFeedComponent implements OnInit {

  displayName: string
  username: string
  emailVerified: boolean
  isDev: boolean
  user: UserModel
  socialPosts: SocialPostModel[]
  lessonPosts: LessonPostModel[]
  courses: CourseEnrollmentModel[]
  profileImg: string
  bio: string
  currentUserObservable: Observable<UserModel>
  currentUser: UserModel

  private paramSubscription: Subscription


  isCurrentUser: boolean


  constructor(private auth: AuthService,
              private userServ: UserDataService,
              private router: Router,
              private route: ActivatedRoute,
              private store: Store<{user: UserModel}>) {



  }

  ngOnInit() {
  }

}
