import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wallets-landing',
  templateUrl: './wallets-landing.component.html',
  styleUrls: ['./wallets-landing.component.scss']
})
export class WalletsLandingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
