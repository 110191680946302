import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/Auth/auth.service';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store'
import {AuthStateEnum} from '../../services/Auth/authstate.action'
import {UserModel} from '../../Models/User.model'
import {Observable} from 'rxjs/internal/Observable'


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup
  username: string
  password: string
  authState$: Observable<AuthStateEnum>

  constructor(private auth: AuthService,
              private router: Router,
              private store: Store<{ authStatus: AuthStateEnum }>) {

    this.authState$ = store.select('authStatus')
    this.authState$.subscribe(state => {

      if (state === AuthStateEnum.Authenticated) {

        console.log('Login Comp.: Authenticated Action called.')
        this.userAuthenticated()
      }
    })

  }

  ngOnInit() {

    this.loginForm = new FormGroup({


      userName: new FormControl(null, [Validators.required]),
      password: new FormControl(null, Validators.required)



    });
  }

  async login() {


    if (this.loginForm.valid){

      this.username = this.loginForm.get('userName').value
      this.password = this.loginForm.get('password').value
      console.log(`login comp.: username ${this.username} password: ${this.password}`)

      const result = await this.auth.login(this.username, this.password)

      if (result === 'success') {

        this.router.navigate(['/home'])

      }else{

        console.log(`login failed`)


      }

    }

  }

  userAuthenticated() {

    this.router.navigate(['/home'])
  }

  goToSignup() {

    // tslint:disable-next-line:prefer-const
    let result = this.router.navigate(['/signup']);
  }

}
