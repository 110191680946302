import {Component, OnDestroy, OnInit} from '@angular/core'
import {UserModel} from '../Models/User.model'
import {SocialPostModel} from '../Models/SocialPost.model'
import {CourseModel} from '../Models/Course.model'
import {LessonPostModel} from '../Models/LessonPost.model'
import {AuthService} from '../services/Auth/auth.service'
import {Router, ActivatedRoute, ParamMap, Params} from '@angular/router'
import {Store} from '@ngrx/store'
import {UserDataService} from '../services/userdata/user-data.service';
import {Observable, Subscription} from 'rxjs'
import {CourseEnrollmentModel} from '../Models/CourseEnrollment.model';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout'


interface UserState {
  user: UserModel;
}

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {


  displayName: string
  username: string
  emailVerified: boolean
  isDev: boolean
  user: UserModel
  socialPosts: SocialPostModel[]
  lessonPosts: LessonPostModel[]
  courses: CourseEnrollmentModel[]
  profileImg: string
  tagline: string
  bio: string
  currentUserObservable: Observable<UserModel>
  currentUser: UserModel

  private paramSubscription: Subscription

  containerClass: string

  isCurrentUser: boolean

  screenSize: string

  constructor(private auth: AuthService,
              private userServ: UserDataService,
              private breakpointObserver: BreakpointObserver,
              private router: Router,
              private route: ActivatedRoute,
              private store: Store<{user: UserModel}>) {

    this.isDev = this.auth.curentDevState

    this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.Handset])
      .subscribe( result => {

        if (result.matches){

          this.handsetLayout()
        }

      })

    this.breakpointObserver.observe([Breakpoints.Tablet, Breakpoints.TabletLandscape, Breakpoints.TabletPortrait])
      .subscribe( result => {

        if (result.matches){

          this.tabletLayout()
        }

      })

    this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.WebLandscape, Breakpoints.Medium])
      .subscribe( result => {

        if (result.matches){

          this.desktopLayout()
        }

      })

    if (this.isDev){
      const devUser: UserModel = new UserModel()
      this.user = this.getDevUser()

      this.tagline = this.user.profileHeadline
      this.profileImg = this.user.profileImgUrl
      this.displayName = this.user.displayName
      this.username = this.user.username

    }else{


    }

  }

  ngOnInit() {

      this.username = this.route.snapshot.paramMap.get('username')

      this.paramSubscription = this.route.params.subscribe((params: Params) => {


        this.username = params.username
      })

      this.currentUserObservable = this.store.select('user')




      this.user = this.userServ.getUserData(this.username)

      this.username = this.user.username

      this.bio = this.user.bio

      this.displayName = this.user.displayName
      this.profileImg = this.user.profileImgUrl
      this.auth.dev.subscribe(devState => {


        this.isDev = devState

      })

      this.currentUserObservable.subscribe(user => {

        this.currentUser = user

        if (this.currentUser.userId === this.user.userId ){

          this.isCurrentUser = true
        } else {


          this.isCurrentUser = false
        }

      })

  }

  goToAbout(): void{

    this.router.navigate(['profile-about', this.user.userId])

  }

  goToSocial(): void{

    this.router.navigate(['profile-social', this.user.userId])

  }

  goToLessons(): void{

    this.router.navigate(['profile-lessons', this.user.userId])


  }

  handsetLayout(): void {

    this.containerClass = 'profile-container-mobile'
    this.screenSize = 'mobile'

  }

  tabletLayout(): void {

    this.containerClass = 'profile-container-tablet'
    this.screenSize = 'tablet'

  }

  desktopLayout(): void {


    this.containerClass = 'profile-container'
    this.screenSize = 'desktop'
  }

  ngOnDestroy(): void {

    // built in Angular observables automatically unsubscribed.  Put here for reference.
    this.paramSubscription.unsubscribe()
  }


  getDevUser(): UserModel{

    const user = new UserModel()


    user.userId = '1'
    user.bio = 'dev user bio'
    user.profileHeadline = 'Dev User Profile Headline'
    user.cartId = '123'
    user.country = 'US'
    user.cognitoId = 'CognitoID1'
    user.confirmed = true
    user.dateOfBirth = new Date('1982-06-21')
    user.cartId = '123'
    user.displayName = 'Dev User'
    user.email = 'dev@somuleco.com'
    user.firstName = 'dev1'
    user.lastName = 'development'
    user.language = 'English'
    user.username = 'devuser'
    user.gender = 'M'
    user.lastUpdate = new Date('2021-11-01')
    user.learningProfileId = '123'
    user.phone = '3109123333'
    user.locale = 'en-US'
    user.profileImgUrl = '../assets/DevImages/Profile.png'



    return  user

  }

}
