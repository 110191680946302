import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lms-sidenav',
  templateUrl: './lms-sidenav.component.html',
  styleUrls: ['./lms-sidenav.component.scss']
})
export class LmsSidenavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
