<main class="library-container">

  <section class="live-header">


  </section>


  <section class="library-content">
    <router-outlet></router-outlet>

  </section>

</main>
