<div class="container">
      <mat-card class="card" style="justify-content: center;">

        <div class="form-container">

        <div class="signup-label">
        <mat-label>Signup</mat-label>
        </div>

    <form  [formGroup]="signupForm" (ngSubmit)="submit()">


      <div class="form-group">


        <mat-form-field appearance="outline" class="form-field fName">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="fName" id="firstName" class="form-control fName" placeholder="First Name" required>
<!--          <mat-hint>Hint</mat-hint>-->
        </mat-form-field>



        <span *ngIf="!signupForm.get('fName').valid && signupForm.touched" class="alert-warning">Please enter a valid first name.</span>




      <mat-form-field appearance="outline" class="form-field lName">
        <mat-label>Last Name</mat-label>
        <input matInput type="text" formControlName="lName" id="lName" class="form-control lName"
               placeholder="Last Name" required>


      </mat-form-field>

        <span *ngIf="!signupForm.get('lName').valid && signupForm.touched" class="alert-warning">Please enter a valid last name.</span>

      <mat-form-field appearance="outline" class="form-field standard">
        <mat-label>Username</mat-label>
        <input matInput type="text" formControlName="userName" id="userName"
               class="form-control standard"
               placeholder="Username">
        <mat-hint>Your Unique Somuleco Username.</mat-hint>

      </mat-form-field>

      <span *ngIf="!signupForm.get('userName').valid && signupForm.touched" class="alert-warning">Please enter a valid user name.</span>


      <mat-form-field appearance="outline" class="form-field standard">
        <mat-label>Display Name</mat-label>

        <input matInput type="text" formControlName="displayName" id="displayName"
               class="form-control standard"
               placeholder="Display Name">
        <mat-hint>Your Display Name is what users will see on your posts and comments.</mat-hint>
      </mat-form-field>



      <mat-form-field appearance="outline" class="form-field standard">
        <mat-label>Email</mat-label>
        <input matInput type="text" formControlName="email" id="email"
               email
               required
               class="form-control"
               placeholder="Email">


      </mat-form-field>


      <span *ngIf="!signupForm.get('email').valid && signupForm.touched" class="alert-warning">Please enter a valid email.</span>




        <mat-form-field appearance="outline" class="form-field dob">
          <mat-label>Date of Birth</mat-label>

          <input matInput formControlName="birthDate" id="birthDate"
                      required
                      class="form-control"
                 [matDatepicker]="dobPicker"
          >
          <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
          <mat-datepicker #dobPicker></mat-datepicker>

        </mat-form-field>

        <!--        <span *ngIf="!signupForm.get('birthDay').valid && signupForm.touched" class="alert-warning">Select Birth Day.</span>-->


<!--        <div class="row">-->

<!--          <div class="col-sm">-->

<!--            <mat-form-field appearance="outline" class="form-field dob-day">-->
<!--              <mat-label>dd</mat-label>-->

<!--        <mat-select type="text" formControlName="birthDay" id="birthDay"-->
<!--               required-->
<!--               class="form-control"-->
<!--                >-->
<!--          <mat-option *ngFor="let day of birthDay" [value]="day">{{day}}</mat-option>-->
<!--        </mat-select>-->
<!--            </mat-form-field>-->

<!--&lt;!&ndash;        <span *ngIf="!signupForm.get('birthDay').valid && signupForm.touched" class="alert-warning">Select Birth Day.</span>&ndash;&gt;-->

<!--          </div>-->

<!--          <div class="col-sm">-->

<!--            <mat-form-field appearance="outline" class="form-field dob-mm">-->
<!--              <mat-label>MM</mat-label>-->

<!--          <mat-select type="text" formControlName="birthMonth" id="birthMonth"-->
<!--                  required-->
<!--                  class="form-control dob-mm"-->
<!--          >-->
<!--            <mat-option *ngFor="let month of birthMonth" [value]="month">{{month}}</mat-option>-->
<!--          </mat-select>-->
<!--            </mat-form-field>-->
<!--&lt;!&ndash;          <span *ngIf="!signupForm.get('birthMonth').valid && signupForm.touched" class="alert-warning">Select Birth Month.</span>&ndash;&gt;-->


<!--          </div>-->

<!--          <div class="col-sm-">-->

<!--            <mat-form-field appearance="outline" class="form-field dob-yyyy">-->
<!--              <mat-label>YYYY</mat-label>-->
<!--          <mat-select type="text" formControlName="birthYear" id="birthYear"-->
<!--                  required-->
<!--                  class="form-control dob-yyyy"-->
<!--          >-->
<!--            <mat-option *ngFor="let year of birthYear" [value]="year">{{year}}</mat-option>-->
<!--          </mat-select>-->
<!--            </mat-form-field>-->
<!--&lt;!&ndash;          <span *ngIf="!signupForm.get('birthYear').valid && signupForm.touched" class="alert-warning">Select Birth Year.</span>&ndash;&gt;-->
<!--          </div>-->
<!--        </div>-->



        <mat-form-field appearance="outline" class="form-field standard">
          <mat-label>Country</mat-label>
        <mat-select type="text" formControlName="country" id="country" class="form-control"
               placeholder="Country" required minlength="8"
        >
          <mat-option *ngFor="let country of countryList" [value]="country">{{country}}</mat-option>

        </mat-select>
        </mat-form-field>
        <span *ngIf="!signupForm.get('country').valid && signupForm.touched" class="alert-warning">Please select a country.</span>


        <mat-form-field appearance="outline" class="form-field standard">
          <mat-label>Password</mat-label>
        <input matInput type="text" formControlName="password" id="password" class="form-control"
               placeholder="Password" required minlength="8"
        >
          <mat-hint>Requirements: Minimum - 8 characters.</mat-hint>
        </mat-form-field>

        <span *ngIf="!signupForm.get('password').valid && signupForm.touched" class="alert-warning">Please enter a valid password.
        Requirements: 8+ Alphanumeric characters</span>


        <mat-form-field appearance="outline" class="form-field standard">
          <mat-label>Confirm Password</mat-label>
        <input matInput type="text" formControlName="confirmPassword" id="confirmPassword"
               class="form-control"
               placeholder="Confirm Password">

        </mat-form-field>




    <button mat-flat-button color="primary" type="submit" class="btn btn-primary button" [disabled]="!signupForm.valid">Submit</button>
      </div>
    </form>

        </div>

      </mat-card>

</div>
