import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-social-feed',
  templateUrl: './profile-social-feed.component.html',
  styleUrls: ['./profile-social-feed.component.scss']
})
export class ProfileSocialFeedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
