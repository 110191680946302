<!--<p class="learning-container">-->
<!--  <b>-->
<!--  my-learning-container works!-->
<!--  </b>-->
<!--</p>-->

<main class="main-container">

  <section class="top-bar">


  </section>
  <section class="left-content">



  </section>

  <section class="learning-feed">


    <div class="home-text">
      <p>Learning Feed Page</p>
    </div>
    <cdk-virtual-scroll-viewport itemSize="250">


    </cdk-virtual-scroll-viewport>


  </section>


  <section class="right-content">



  </section>


</main>
