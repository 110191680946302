import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-lesson-post',
  templateUrl: './create-lesson-post.component.html',
  styleUrls: ['./create-lesson-post.component.scss']
})
export class CreateLessonPostComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
