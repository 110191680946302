<div class="container">

  <div class="row">


    <div class="col">
      <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            <em>Course Info</em>
          </ng-template>
          <edit-course-info></edit-course-info>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <!--              <mat-icon>thumb_down</mat-icon> The worst sushi-->
            <em>Content</em>
          </ng-template>
          <edit-course-content></edit-course-content>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <!--              <mat-icon>thumb_down</mat-icon> The worst sushi-->
            <em>Roles</em>
          </ng-template>
          <edit-course-roles></edit-course-roles>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <!--              <mat-icon>thumb_down</mat-icon> The worst sushi-->
            <em>Credentials</em>
          </ng-template>
          <edit-course-credentials></edit-course-credentials>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <!--              <mat-icon>thumb_down</mat-icon> The worst sushi-->
            <em>Students</em>
          </ng-template>
          <edit-course-students></edit-course-students>
        </mat-tab>
      </mat-tab-group>


    </div>
  </div>
</div>
