<!-- Side Nav -->
<nav class="main-menu">
  <ul>
    <li>
      <a routerLink="lmsdashboard" mdbRippleRadius>
        <mat-icon svgIcon="mylearning" class="icons"></mat-icon>
        <span class="nav-text">
                          Dashboard
                        </span>

      </a>

    </li>

    <li>
      <a routerLink="lmscreate" mdbRippleRadius>
        <mat-icon svgIcon="mycommunity" class="icons"></mat-icon>
        <span class="nav-text">
                          Create
                        </span>

      </a>

    </li>

    <li  class="has-subnav">
      <a routerLink="lmsedit" mdbRippleRadius>
        <mat-icon svgIcon="library" class="icons"> </mat-icon>
        <span class="nav-text">
                            Edit
                        </span>

      </a>

    </li>
    <li class="has-subnav">
      <a routerLink="lmsadmin" >
        <mat-icon svgIcon="wallet" class="icons"> </mat-icon>
        <span class="nav-text">
                           Admin
                        </span>

      </a>

    </li>
    <li>
      <a routerLink="lmsanalytics" >
        <mat-icon svgIcon="learningprofile" class="icons"> </mat-icon>
        <span class="nav-text">
                            Analytics
                        </span>

      </a>
    </li>
  </ul>
</nav>


