import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'onboarding-container',
  templateUrl: './onboarding-container.component.html',
  styleUrls: ['./onboarding-container.component.scss']
})
export class OnboardingContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
