import {Directive, ElementRef, HostBinding, HostListener, Renderer2} from '@angular/core';

@Directive({
  selector: '[appTestHighlight]'
})
export class TestHighlightDirective {

  @HostBinding('style.backgroundColor') backgroundColor: string

  constructor(private elRef: ElementRef, private renderer: Renderer2) { }


  @HostListener('mouseenter') mouseover(eventData: Event) {

    this.backgroundColor = 'blue'

  }

}
