import { Injectable } from '@angular/core'
import {HttpClient, HttpParams} from '@angular/common/http'
import {AuthService} from '../Auth/auth.service'
import {UserModel} from '../../Models/User.model'


@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  apiURL = 'https://somulecoapidev.com.com'

  user: UserModel
  cognitoUser


  constructor(private http: HttpClient, private auth: AuthService) {

    this.cognitoUser = auth.cognitoUser


  }


  getUserData(userName: string) {

    userName = userName.trim()

    const options = userName ?
      { params: new HttpParams().set('username', userName)} : {}

    this.http.get<UserModel>(this.apiURL + '/user', options)
      .subscribe((user) => {

        this.user.userId = user.userId
        this.user.cognitoId = user.cognitoId
        this.user.username = user.username
        this.user.email = user.email
        this.user.displayName = user.displayName
        this.user.firstName = user.firstName
        this.user.lastName = user.lastName
        this.user.bio = user.bio

      })

    return this.user

  }

  getUserId(username, email) {



  }





}
