import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import {UnauthenticatedHomeComponent} from './unauthenticated-home/unauthenticated-home.component';
import {MyLearningContainerComponent} from './authenticated/my-learning-container/my-learning-container.component';
import {MyCommunityContainerComponent} from './authenticated/my-community-container/my-community-container.component';
import {WalletComponent} from './authenticated/wallet/wallet.component';
import {LibraryComponent} from './authenticated/library/library.component';
import {SchoolsComponent} from './authenticated/schools/schools.component';
import {IdeasComponent} from './authenticated/ideas/ideas.component';
import {LoginComponent} from './unauthenticated/login/login.component';
import {SignupComponent} from './unauthenticated/signup/signup.component';
import {main} from '@angular/compiler-cli/src/main';
import {AuthGuardService} from './auth-guard.service';
import {SignupConfirmationComponent} from './unauthenticated/signup-confirmation/signup-confirmation.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import {UserHomeComponent} from './authenticated/user-home/user-home.component';
import {EditUserProfileComponent} from './authenticated/edit-user-profile/edit-user-profile.component';
import {AuthenticationContainerComponent} from './unauthenticated/authentication-container/authentication-container.component'
import {LMSDashboardPageComponent} from './learning-management-system/dashboard-page/lms-dashboard-page.component'
import {CreatePageComponent} from './learning-management-system/create-page/create-page.component'
import {EditPageComponent} from './learning-management-system/edit-page/edit-page.component'
import {AdminPageComponent} from './learning-management-system/admin-page/admin-page.component'
import {AnalyticsPageComponent} from './learning-management-system/analytics-page/analytics-page.component'
import {MatCardModule} from '@angular/material/card'
import {NotificationsComponent} from './authenticated/notifications/notifications.component'
import {CartComponent} from './authenticated/cart/cart.component'
import {CheckoutComponent} from './authenticated/checkout/checkout.component'
import {ChatComponent} from './authenticated/chat/chat.component'
import {SocialFeedComponent} from './authenticated/social-feed/social-feed.component'
import {MyLearningProfileComponent} from './authenticated/my-learning-profile/my-learning-profile.component'
import {ProfileLessonFeedComponent} from './user-profile/profile-lesson-feed/profile-lesson-feed.component'
import {ProfileSocialFeedComponent} from './user-profile/profile-social-feed/profile-social-feed.component'
import {ProfileAboutMeComponent} from './user-profile/profile-about-me/profile-about-me.component'
import {LiveSearchContainerComponent} from './authenticated/live-search-container/live-search-container.component'
import {LiveSessionsSearchResultsComponent} from './authenticated/live-sessions-search-results/live-sessions-search-results.component'
import {LiveSessionsComponent} from './authenticated/live-sessions/live-sessions.component'
import {LibraryLandingComponent} from './authenticated/library/library-landing/library-landing.component'
import {LibrarySearchResultsComponent} from './authenticated/library/library-search-results/library-search-results.component'
import {SitemapComponent} from './sitemap/sitemap.component'
import {CreateSocialPostComponent} from './authenticated/create-social-post/create-social-post.component'
import {CreateLessonPostComponent} from './authenticated/create-lesson-post/create-lesson-post.component'
import {LessonPostComponent} from './authenticated/lesson-post/lesson-post.component'
import {SocialPostComponent} from './authenticated/social-post/social-post.component'
import {SocialPostPageComponent} from './authenticated/social-post-page/social-post-page.component'
import {LessonPostPageComponent} from './authenticated/lesson-post-page/lesson-post-page.component'
import {CourseComponent} from './course/course.component'
import {EditCourseComponent} from './course/edit-course/edit-course.component'
import {VideoLessonPageComponent} from './course/video-lesson-page/video-lesson-page.component'
import {CourseAssessmentPageComponent} from './course/course-assessment-page/course-assessment-page.component'

const routes: Routes = [
  { path: 'mylearning', canActivate: [AuthGuardService], component: MyLearningContainerComponent },
  { path: 'community', canActivate: [AuthGuardService], component: MyCommunityContainerComponent },
  { path: 'library', canActivate: [AuthGuardService], component: LibraryComponent },
  { path: 'library-landing', canActivate: [AuthGuardService], component: LibraryLandingComponent},
  { path: 'library-search', canActivate: [AuthGuardService], component: LibrarySearchResultsComponent},
  { path: 'wallet', canActivate: [AuthGuardService], component: WalletComponent},
  { path: 'schools', canActivate: [AuthGuardService], component: SchoolsComponent },
  { path: 'ideas', canActivate: [AuthGuardService], component: IdeasComponent},
  { path: 'notifications', canActivate: [AuthGuardService], component: NotificationsComponent},
  { path: 'learningprofile', canActivate: [AuthGuardService], component: MyLearningProfileComponent},
  { path: 'cart', canActivate: [AuthGuardService], component: CartComponent},
  { path: 'checkout', canActivate: [AuthGuardService], component: CheckoutComponent},
  { path: 'live', canActivate: [AuthGuardService], component: LiveSearchContainerComponent},
  { path: 'live-search-results', canActivate: [AuthGuardService], component: LiveSessionsSearchResultsComponent},
  { path: 'live-landing', canActivate: [AuthGuardService], component: LiveSessionsComponent},
  { path: 'chat', canActivate: [AuthGuardService], component: ChatComponent},
  { path: 'social', canActivate: [AuthGuardService], component: SocialFeedComponent},
  { path: 'profile-about/:id', canActivate: [AuthGuardService], component: ProfileAboutMeComponent},
  { path: 'profile-social/:id', canActivate: [AuthGuardService], component: ProfileSocialFeedComponent},
  { path: 'profile-lessons/:id', canActivate: [AuthGuardService], component: ProfileLessonFeedComponent},
  { path: 'course/:id', component: CourseComponent},
  { path: 'editcourse/:id', component: EditCourseComponent},
  {path: 'lesson/:id', component: VideoLessonPageComponent},
  {path: 'assessment/:id', component: CourseAssessmentPageComponent},
  {path: 'authentication', component: AuthenticationContainerComponent},
  { path: 'unauthenticated', component: UnauthenticatedHomeComponent},
  { path: 'login', component: LoginComponent},
  { path: 'signup', component: SignupComponent},
  { path: 'sitemap', component: SitemapComponent},
  { path: 'confirm', component: SignupConfirmationComponent},
  { path: 'home',  canActivate: [AuthGuardService], component: UserHomeComponent},
  { path: 'lms', canActivate: [AuthGuardService], component: LMSDashboardPageComponent},
  { path: 'lmsdashboard', canActivate: [AuthGuardService], component: LMSDashboardPageComponent},
  { path: 'lmscreate', canActivate: [AuthGuardService], component: CreatePageComponent},
  { path: 'lmsedit', canActivate: [AuthGuardService], component: EditPageComponent},
  { path: 'lmsadmin', canActivate: [AuthGuardService], component: AdminPageComponent},
  { path: 'lmsanalytics', canActivate: [AuthGuardService], component: AnalyticsPageComponent},
  { path: 'profile/:username',  canActivate: [AuthGuardService], component: UserProfileComponent },
  { path: 'site-dev-create-social-post', component: CreateSocialPostComponent},
  { path: 'site-dev-create-lesson-post',  component: CreateLessonPostComponent},
  { path: 'site-dev-lesson-post', component: LessonPostComponent},
  { path: 'site-dev-social-post', component: SocialPostComponent},
  { path: 'site-dev-social-post-page',  component: SocialPostPageComponent},
  { path: 'site-dev-lesson-post-page',  component: LessonPostPageComponent},
  { path: 'editprofile', component: EditUserProfileComponent},
  { path: '',   redirectTo: '/unauthenticated-home', pathMatch: 'full'},
  { path: '**', component: UnauthenticatedHomeComponent }

];

@NgModule({
  imports: [
    RouterModule,
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
