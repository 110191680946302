
<mat-toolbar class="top-nav-container">

  <span class="logo">
  <img src="assets/AppIcon/AppIcon.png" height="50" width="45" mat-ripple routerLink="/unauthenticated"/>
    </span>



  <!-- This fills the remaining space of the current row -->
  <span class="search-container"> <mat-form-field class="search">
    <input matInput placeholder="Search">
  </mat-form-field></span>


  <span class="toolbar-item" *ngIf="lms && authenticated"><button mat-button (click)="goToSomuleco()" style="{height: 70px; width: 70px; font-size: 42px}"> Somuleco </button></span>


  <span class="toolbar-item"><button mat-button (click)="goToSiteMap()" style="{height: 70px; width: 70px; font-size: 42px}"> Site Map </button></span>

  <span class="toolbar-item" *ngIf="!lms && authenticated"><button mat-button (click)="goToLMS()" style="{height: 70px; width: 70px; font-size: 42px}"> LMS </button></span>

  <span class="toolbar-item" *ngIf="!authenticated"><button mat-button (click)="authenticate()" style="{height: 70px; width: 70px; font-size: 42px}"> Authenticate </button></span>

  <span class="toolbar-item" *ngIf="authenticated"><button mat-button (click)="unAuthenticate()" style="{height: 70px; width: 70px; font-size: 42px}"> UnAuthenticate </button></span>

  <span class="toolbar-item" *ngIf="!authenticated"><button mat-button (click)="goToLogin()" style="{height: 70px; width: 70px; font-size: 42px}"> Login/Signup </button></span>

 <span *ngIf="authenticated" class="auth-item-container">

   <a routerLink="cart"> <span><mat-icon class="toolbar-item" svgIcon="cart" routerLink="cart" ></mat-icon></span></a>

   <a routerLink="notifications"> <span><mat-icon class="toolbar-item" svgIcon="notification" routerLink="notifications"></mat-icon></span></a>
   <a routerLink="chat"> <span><mat-icon class="toolbar-item"  svgIcon="chat" routerLink="chat" ></mat-icon></span></a>
   <a [routerLink]="['/profile', username]"> <span><mat-icon class="toolbar-item" svgIcon="user" [routerLink]="['/profile', username]" ></mat-icon></span></a>


  </span>


</mat-toolbar>

