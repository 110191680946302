import {Action, createReducer, on} from '@ngrx/store'
import {UserAuthenticated, UserUnauthenticated, AuthStateEnum} from '../services/Auth/authstate.action'
import {USER_AUTHENTICATED, USER_UNAUTHENTICATED, USER_MUST_CONFIRM} from '../services/Auth/authstate.action'


export interface State {

  authStatus: AuthStateEnum
}

export const initialState: State = {
  authStatus: AuthStateEnum.Unauthenticated

}

// const reducer = createReducer(
//   initialState,
//   on(authStateAction, state => ({ ...state, authStatus: state.authStatus }))
// )

export function authStateReducer(state = initialState, action: Action) {

    switch (action.type){

      case USER_AUTHENTICATED:
        return {...state, authStatus: AuthStateEnum.Authenticated}
      case USER_UNAUTHENTICATED:
        return {...state, authStatus: AuthStateEnum.Unauthenticated}
      case USER_MUST_CONFIRM:
        return {...state, authStatus: AuthStateEnum.Confirm}
      default:
        return {...state, authStatus: AuthStateEnum.Unauthenticated}


    }

   // return {...state, authStatus: action.payload}
}
