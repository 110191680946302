import { Component, OnInit } from '@angular/core';
import {CourseModel} from '../Models/Course.model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {LearningSubjectModel} from '../Models/LearningSubject.model';
import {InstructorModel} from '../Models/Instructor.model';
import {UserModel} from '../Models/User.model';
import {UserDataService} from '../services/userdata/user-data.service';
import {CourseService} from '../services/course/course.service';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss']
})
export class CourseComponent implements OnInit {

  course: CourseModel

  introVidURL: string

  language: string

 coursePosterUrl: string

  courseName: string

  user: UserModel

  courseOwner: boolean

  enrolled: boolean

  courseAdmin: boolean

  title: string

  subjects: [LearningSubjectModel]

  instructors: [InstructorModel]

  level: string

  rating: string

  price: string

  poolPercentage: string

  subject1: string

  subject2: string

  subject3: string

  subject4: string



  constructor(
    private route: ActivatedRoute,
    private userServ: UserDataService,
    private courseServ: CourseService) {



  }

  ngOnInit() {


  }


  onAddToCart() {


  }


  onEdit() {




  }


}
