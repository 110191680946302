<div class="flex-container">

  <div>
    <video [src]="lessonVid"></video>

  </div>

  <div>

  </div>


  <div>

  </div>


</div>
