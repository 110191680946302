import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'course-lesson',
  templateUrl: './course-lesson.component.html',
  styleUrls: ['./course-lesson.component.scss']
})
export class CourseLessonComponent implements OnInit {


  lessonVid: string
  constructor() { }

  ngOnInit() {
  }

}
