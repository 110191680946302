
<main class="main-container">

  <section class="top-bar">


  </section>
  <section class="left-content">



  </section>

  <section class="social-feed">

    <div class="home-text">
      <p>Social/Community Page</p>
    </div>
    <cdk-virtual-scroll-viewport itemSize="250">


    </cdk-virtual-scroll-viewport>


  </section>


  <section class="right-content">



  </section>


</main>
