import { userStateChangeAction } from '../actions/user-state-change.action';
import {Action, createReducer, on} from '@ngrx/store';
import {authStateAction} from '../services/Auth/authstate.action';
import {UserModel} from '../Models/User.model'

export interface State {

  user: UserModel
}

export const initialState: State = {
  user: new UserModel()

}

const reducer = createReducer(
  initialState,
  on(userStateChangeAction, state => ({ ...state, user: state.user }))
)

export function userStateChangeReducer(state = initialState, action: Action) {

  return reducer(state, action)
}
