import {Component, OnDestroy, OnInit} from '@angular/core'
import {AuthService} from './services/Auth/auth.service'
import {Observable} from 'rxjs/internal/Observable'
import 'rxjs'
import {HttpClient} from '@angular/common/http'
import {LmsService} from './services/lms/lms.service'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{

  title = 'Somuleco';


  authenticated: boolean;
  lms: boolean




  constructor(private auth: AuthService, private lmsServ: LmsService, private http: HttpClient) {

    this.lms = false

    this.lmsServ.onLMS.subscribe( status => {


      this.lms = status

    })

  }




  ngOnInit(){

    this.auth.authenticated
      .subscribe(
        (status) => {
          this.authenticated = status;
        }

      );

    this.lmsServ.onLMS.subscribe( status => {


      this.lms = status

    })


  }



  ngOnDestroy(){

  }

}
