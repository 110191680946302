import { Component, OnInit } from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthService} from '../services/Auth/auth.service';


@Component({
  selector: 'side-drawer-nav',
  templateUrl: './side-drawer-nav.component.html',
  styleUrls: ['./side-drawer-nav.component.scss']
})
export class SideDrawerNavComponent implements OnInit {

  auth

  constructor(private matIconReg: MatIconRegistry, private domSanitizer: DomSanitizer, auth: AuthService) {

    this.matIconReg.addSvgIcon('house', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/SideNavIcons/home.svg'))
    this.matIconReg.addSvgIcon('library', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/SideNavIcons/Library.svg'))
    this.matIconReg.addSvgIcon('wallet', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/SideNavIcons/wallet.svg'))
    this.matIconReg.addSvgIcon('learningprofile', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/SideNavIcons/LearningProfile.svg'))
    this.matIconReg.addSvgIcon('ideas', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/SideNavIcons/Ideas.svg'))
    this.matIconReg.addSvgIcon('jobs', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/SideNavIcons/Jobs.svg'))
    this.matIconReg.addSvgIcon('schools', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/SideNavIcons/Schools.svg'))
    this.matIconReg.addSvgIcon('mylearning', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/SideNavIcons/mylearning.svg'))
    this.matIconReg.addSvgIcon('mycommunity', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/SideNavIcons/community.svg'))
    this.matIconReg.addSvgIcon('live', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/SideNavIcons/live.svg'))


    this.auth = auth
  }

  ngOnInit() {




  }



   logout(){

    this.auth.logout()
   }

}
