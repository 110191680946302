<!-- Side Nav -->
<nav class="main-menu">
  <ul>

    <li>
      <a routerLink="home" mdbRippleRadius>
        <mat-icon svgIcon="house" class="icons"></mat-icon>
        <span class="nav-text">
                           Home
                        </span>

      </a>

    </li>

    <li>
      <a routerLink="mylearning" mdbRippleRadius>
        <mat-icon svgIcon="mylearning" class="icons"></mat-icon>
        <span class="nav-text">
                           My Learning
                        </span>

      </a>

    </li>

    <li>
      <a routerLink="community" mdbRippleRadius>
        <mat-icon svgIcon="mycommunity" class="icons"></mat-icon>
        <span class="nav-text">
                           My Community
                        </span>

      </a>

    </li>

    <li  class="has-subnav">
      <a routerLink="/library" mdbRippleRadius>
        <mat-icon svgIcon="library" class="icons"> </mat-icon>
        <span class="nav-text">
                            Library
                        </span>

      </a>

    </li>
    <li  class="has-subnav">
      <a routerLink="/live" mdbRippleRadius>
        <mat-icon svgIcon="live" class="icons"> </mat-icon>
        <span class="nav-text">
                            Live
                        </span>

      </a>

    </li>
    <li class="has-subnav">
      <a routerLink="/wallet" >
        <mat-icon svgIcon="wallet" class="icons"> </mat-icon>
        <span class="nav-text">
                           Wallet
                        </span>

      </a>

    </li>
    <li>
      <a routerLink="/learningprofile" >
        <mat-icon svgIcon="learningprofile" class="icons"> </mat-icon>
        <span class="nav-text">
                            Learning Profile
                        </span>

      </a>
    </li>
    <li>
      <a routerLink="ideas" mdbRippleRadius>
        <mat-icon svgIcon="ideas" class="icons"></mat-icon>
        <span class="nav-text">
                           Ideas
                        </span>

      </a>
    </li>
    <li>
      <a routerLink="jobs" mdbRippleRadius>
        <mat-icon svgIcon="jobs" class="icons"></mat-icon>
        <span class="nav-text">
                            Jobs
                        </span>

      </a>
    </li>
    <li>
      <a routerLink="schools" mdbRippleRadius>
        <mat-icon svgIcon="schools" class="icons"> </mat-icon>
        <span class="nav-text">
                           Schools
                        </span>

      </a>
    </li>
  </ul>

  <ul class="logout">
    <li>
      <a routerLink=" " (click)="logout()" mdbRippleRadius>
        <i class="fa fa-power-off fa-2x icons"></i>
        <span class="nav-text">
                            Logout
                        </span>
      </a>
    </li>
  </ul>
</nav>


