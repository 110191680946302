import { Component, OnInit } from '@angular/core';
import {Form, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AuthService} from '../services/Auth/auth.service';
import {Router} from '@angular/router';


@Component({
  selector: 'app-unauthenticated-home',
  templateUrl: './unauthenticated-home.component.html',
  styleUrls: ['./unauthenticated-home.component.scss']
})
export class UnauthenticatedHomeComponent implements OnInit {


  authenticated


  loginForm = new FormGroup({


    username: new FormControl(),
    password: new FormControl()



  })


  signupForm = new FormGroup({


    fullName: new FormControl(),
    email: new FormControl(),
    password: new FormControl()


  })


  constructor(private auth: AuthService, private router: Router) {



  }

  ngOnInit() {

    this.auth.authenticated
      .subscribe(
        (status) => {

          this.authenticated = status
          if (this.authenticated === true) {

            this.router.navigate(['/home'])

          }
          else if (this.authenticated === false){
            this.router.navigate(['/unauthenticated'])

          }

        }
      );
  }


  login(){






  }

}
