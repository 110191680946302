import 'hammerjs'
import { BrowserModule } from '@angular/platform-browser'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import {HttpClientModule} from '@angular/common/http'
import {FormsModule} from '@angular/forms'
import {ReactiveFormsModule} from '@angular/forms'
import { FlexLayoutModule } from '@angular/flex-layout'
import {DatePipe} from '@angular/common'
import {ScrollingModule} from '@angular/cdk/scrolling'


// AWS
import {AmplifyAngularModule, AmplifyModules, AmplifyService} from 'aws-amplify-angular'
import Auth from '@aws-amplify/auth'
import Interactions from '@aws-amplify/interactions'
import Storage from '@aws-amplify/storage'


import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {MaterialComponentModule} from '../Modules/md-component.module'

// Storage
import { AngularWebStorageModule } from 'angular-web-storage'
import {NgxWebstorageModule} from 'ngx-webstorage'

import { AppRoutingModule } from './app-routing.module'


import { AppComponent } from './app.component'
import {TopNavBarComponent} from './top-nav-bar/top-nav-bar.component'
import {SideDrawerNavComponent} from './side-drawer-nav/side-drawer-nav.component'
import {UnauthenticatedHomeComponent} from './unauthenticated-home/unauthenticated-home.component'
import {MyLearningContainerComponent} from './authenticated/my-learning-container/my-learning-container.component'
import {MyCommunityContainerComponent} from './authenticated/my-community-container/my-community-container.component'
import {WalletComponent} from './authenticated/wallet/wallet.component'
import {LibraryComponent} from './authenticated/library/library.component'
import {SchoolsComponent} from './authenticated/schools/schools.component'
import {IdeasComponent} from './authenticated/ideas/ideas.component'



import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {JobsContainerComponent} from './authenticated/jobs-container/jobs-container.component';
import {AuthService} from './services/Auth/auth.service';
import {AuthGuardService} from './auth-guard.service';
import { LoginComponent } from './unauthenticated/login/login.component';
import { SignupComponent } from './unauthenticated/signup/signup.component';

import { SocialFeedComponent } from './authenticated/social-feed/social-feed.component';
import { SocialExploreComponent } from './authenticated/social-explore/social-explore.component';
import { SocialPostComponent } from './authenticated/social-post/social-post.component';
import { LearningFeedComponent } from './authenticated/learning-feed/learning-feed.component';
import { LearningExploreComponent } from './authenticated/learning-explore/learning-explore.component';
import { LearningPostComponent } from './authenticated/learning-post/learning-post.component';
import { ChatComponent } from './authenticated/chat/chat.component';
import { ChatSessionsComponent } from './authenticated/chat-sessions/chat-sessions.component';
import { CreateCourseComponent } from './authenticated/create-course/create-course.component';
import { CartComponent } from './authenticated/cart/cart.component';
import { CheckoutComponent } from './authenticated/checkout/checkout.component';
import { OrderConfirmationComponent } from './authenticated/order-confirmation/order-confirmation.component';
import { LessonPostComponent } from './authenticated/lesson-post/lesson-post.component';
import { NotificationsComponent } from './authenticated/notifications/notifications.component';
import { CommentsComponent } from './comments/comments.component';
import { CourseComponent } from './course/course.component';
import { AuthenticationContainerComponent } from './unauthenticated/authentication-container/authentication-container.component';
import { LoginSignupContainerComponent } from './unauthenticated/login-signup-container/login-signup-container.component';
import { OnboardingContainerComponent } from './unauthenticated/onboarding-container/onboarding-container.component';
import {UserProfileComponent} from './user-profile/user-profile.component';
import { CourseLessonContainerComponent } from './authenticated/course-lesson-container/course-lesson-container.component';
import { CourseLessonComponent } from './authenticated/course-lesson/course-lesson.component';
import { TestHighlightDirective } from './directives/test-highlight.directive';
import { UnauthorizedComponent } from './error-pages/unauthorized/unauthorized.component';
import { NotFoundComponent } from './error-pages/not-found/not-found.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { GraphQLModule } from './graphql.module';
import {EditUserProfileComponent} from './authenticated/edit-user-profile/edit-user-profile.component';
import {MyLearningProfileComponent} from './authenticated/my-learning-profile/my-learning-profile.component';
import { SignupConfirmationComponent } from './unauthenticated/signup-confirmation/signup-confirmation.component';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLinkModule, HttpLink} from 'apollo-angular-link-http';

import {InMemoryCache} from 'apollo-cache-inmemory';
import { UserHomeComponent } from './authenticated/user-home/user-home.component';
import { EditCourseComponent } from './course/edit-course/edit-course.component';
// tslint:disable-next-line:max-line-length
import { CourseOwnersInstructorsAdminsComponent } from './course/course-owners-instructors-admins/course-owners-instructors-admins.component';
import { CourseAssessmentComponent } from './authenticated/course-assessment/course-assessment.component';
// tslint:disable-next-line:max-line-length
import { CourseAssessmentProgressComponent } from './authenticated/course-assessment/course-assessment-progress/course-assessment-progress.component';
import { CourseLessonProgressComponent } from './authenticated/course-lesson/course-lesson-progress/course-lesson-progress.component';
import { EditCourseInfoComponent } from './course/edit-course/edit-course-info/edit-course-info.component';
import { EditCourseContentComponent } from './course/edit-course/edit-course-content/edit-course-content.component';
import { EditSectionComponent } from './course/edit-course/edit-course-content/edit-section/edit-section.component';
import { EditLessonComponent } from './course/edit-course/edit-course-content/edit-lesson/edit-lesson.component';
import { EditAssessmentComponent } from './course/edit-course/edit-course-content/edit-assessment/edit-assessment.component';
import { CourseInfoComponent } from './course/course-info/course-info.component';
import { CourseFeedComponent } from './course/course-feed/course-feed.component';
import { CourseReviewsComponent } from './course/course-reviews/course-reviews.component';
import { CourseStudentsComponent } from './course/course-students/course-students.component';
import { CourseContentComponent } from './course/course-content/course-content.component';
import { EditCourseRolesComponent } from './course/edit-course/edit-course-roles/edit-course-roles.component';
import { EditCourseCredentialsComponent } from './course/edit-course/edit-course-credentials/edit-course-credentials.component';
import { EditCourseStudentsComponent } from './course/edit-course/edit-course-students/edit-course-students.component';
import { MyCoursesComponent } from './authenticated/my-courses/my-courses.component';
import { MyPoolsComponent } from './authenticated/my-pools/my-pools.component';
import { PoolComponent } from './pool/pool.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LandingPageComponent } from './learning-management-system/landing-page/landing-page.component';
import { CreatePageComponent } from './learning-management-system/create-page/create-page.component';
import { EditPageComponent } from './learning-management-system/edit-page/edit-page.component';
import { AdminPageComponent } from './learning-management-system/admin-page/admin-page.component';
import { AnalyticsPageComponent } from './learning-management-system/analytics-page/analytics-page.component';
import { LMSDashboardPageComponent } from './learning-management-system/dashboard-page/lms-dashboard-page.component';
import { CreateCoursePageComponent } from './learning-management-system/create-course-page/create-course-page.component';
import { CreateAssessmentPageComponent } from './learning-management-system/create-assessment-page/create-assessment-page.component';
import { EditCoursePageComponent } from './learning-management-system/edit-course-page/edit-course-page.component';
import { LmsPageComponent } from './learning-management-system/lms-page/lms-page.component';
import { LmsSidenavComponent } from './learning-management-system/lms-sidenav/lms-sidenav.component';
import {RouterModule} from '@angular/router';
import { ProfileAboutMeComponent } from './user-profile/profile-about-me/profile-about-me.component';
import { ProfileSocialFeedComponent } from './user-profile/profile-social-feed/profile-social-feed.component';
import { ProfileLessonFeedComponent } from './user-profile/profile-lesson-feed/profile-lesson-feed.component';
import { LiveSearchContainerComponent } from './authenticated/live-search-container/live-search-container.component';
import { LiveSessionsComponent } from './authenticated/live-sessions/live-sessions.component';
import { LiveSessionsSearchResultsComponent } from './authenticated/live-sessions-search-results/live-sessions-search-results.component';
import { LibrarySearchResultsComponent } from './authenticated/library/library-search-results/library-search-results.component';
import { LibraryLandingComponent } from './authenticated/library/library-landing/library-landing.component';
import { WalletsLandingComponent } from './authenticated/wallet/wallets-landing/wallets-landing.component';
import { PoolsContainerComponent } from './authenticated/wallet/pools-container/pools-container.component';
import { TransactionsComponent } from './authenticated/wallet/transactions/transactions.component';
import { TransfersComponent } from './authenticated/wallet/transfers/transfers.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { CreateLessonPostComponent } from './authenticated/create-lesson-post/create-lesson-post.component';
import { CreateSocialPostComponent } from './authenticated/create-social-post/create-social-post.component';
import { SocialPostPageComponent } from './authenticated/social-post-page/social-post-page.component';
import { LessonPostPageComponent } from './authenticated/lesson-post-page/lesson-post-page.component';
import { VideoLessonPageComponent } from './course/video-lesson-page/video-lesson-page.component';
import { CourseAssessmentPageComponent } from './course/course-assessment-page/course-assessment-page.component'

import {MatNativeDateModule} from '@angular/material/core'


@NgModule({
  declarations: [
    AppComponent,
    TopNavBarComponent,
    SideDrawerNavComponent,
    MyCommunityContainerComponent,
    MyLearningContainerComponent,
    LibraryComponent,
    IdeasComponent,
    JobsContainerComponent,
    SchoolsComponent,
    WalletComponent,
    UnauthenticatedHomeComponent,
    LoginComponent,
    SignupComponent,
    SocialFeedComponent,
    SocialExploreComponent,
    SocialPostComponent,
    LearningFeedComponent,
    LearningExploreComponent,
    LearningPostComponent,
    ChatComponent,
    ChatSessionsComponent,
    CreateCourseComponent,
    CartComponent,
    CheckoutComponent,
    OrderConfirmationComponent,
    LessonPostComponent,
    NotificationsComponent,
    UserProfileComponent,
    CommentsComponent,
    CourseComponent,
    EditUserProfileComponent,
    MyLearningProfileComponent,
    AuthenticationContainerComponent,
    LoginSignupContainerComponent,
    OnboardingContainerComponent,
    CourseLessonContainerComponent,
    CourseLessonComponent,
    TestHighlightDirective,
    UnauthorizedComponent,
    NotFoundComponent,
    SignupConfirmationComponent,
    UserHomeComponent,
    EditCourseComponent,
    CourseOwnersInstructorsAdminsComponent,
    CourseAssessmentComponent,
    CourseAssessmentProgressComponent,
    CourseLessonProgressComponent,
    EditCourseInfoComponent,
    EditCourseContentComponent,
    EditSectionComponent,
    EditLessonComponent,
    EditAssessmentComponent,
    CourseInfoComponent,
    CourseFeedComponent,
    CourseReviewsComponent,
    CourseStudentsComponent,
    CourseContentComponent,
    EditCourseRolesComponent,
    EditCourseCredentialsComponent,
    EditCourseStudentsComponent,
    MyCoursesComponent,
    MyPoolsComponent,
    PoolComponent,
    LandingPageComponent,
    CreatePageComponent,
    EditPageComponent,
    AdminPageComponent,
    AnalyticsPageComponent,
    LMSDashboardPageComponent,
    CreateCoursePageComponent,
    CreateAssessmentPageComponent,
    EditCoursePageComponent,
    LmsPageComponent,
    LmsSidenavComponent,
    ProfileAboutMeComponent,
    ProfileSocialFeedComponent,
    ProfileLessonFeedComponent,
    LiveSearchContainerComponent,
    LiveSessionsComponent,
    LiveSessionsSearchResultsComponent,
    LibrarySearchResultsComponent,
    LibraryLandingComponent,
    WalletsLandingComponent,
    PoolsContainerComponent,
    TransactionsComponent,
    TransfersComponent,
    SitemapComponent,
    CreateLessonPostComponent,
    CreateSocialPostComponent,
    SocialPostPageComponent,
    LessonPostPageComponent,
    VideoLessonPageComponent,
    CourseAssessmentPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialComponentModule,
    MatNativeDateModule,
    FormsModule,
    FlexLayoutModule,
    NgxWebstorageModule.forRoot(),
    AmplifyAngularModule,
    ReactiveFormsModule,
    ScrollingModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true
      }
    }),
    HttpLinkModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    AuthService,
    DatePipe,
    AuthGuardService,
    {
      provide: AmplifyService,
      useFactory:  () => {
        return AmplifyModules({
          Auth,
          Storage,
          Interactions
        })
      }
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: ''
          })
        }
      },
      deps: [HttpLink]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
