import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'login-signup-container',
  templateUrl: './login-signup-container.component.html',
  styleUrls: ['./login-signup-container.component.scss']
})
export class LoginSignupContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
