import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'edit-course-students',
  templateUrl: './edit-course-students.component.html',
  styleUrls: ['./edit-course-students.component.scss']
})
export class EditCourseStudentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
