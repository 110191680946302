import { createAction, props } from '@ngrx/store'
import {UserModel} from '../Models/User.model'


export const userStateChangeAction = createAction(
  '[Global] user state change',
  props<{
    user: UserModel
  }>()
)

