import { Component, OnInit } from '@angular/core'
import {DatePipe} from '@angular/common'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {UserDataService} from '../../services/userdata/user-data.service'
import {AuthService} from '../../services/Auth/auth.service'
import {UserModel} from '../../Models/User.model'
import {Router} from '@angular/router'
import { Observable, throwError } from 'rxjs'
import { catchError, retry } from 'rxjs/operators'
import {Store} from '@ngrx/store'
import {AuthStateEnum} from '../../services/Auth/authstate.action'

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signupForm: FormGroup
  result: string
  user: UserModel
  dob: string

  signupFName: string
  signupLName: string
  singupEmail: string
  signupUsername: string
  signupDisplayName: string
  signupBirthDay: number
  signupBirthMonth: number
  signupBirthYear: number
  signupPassword: string

  genderList: string[] = ['male', 'female']

  countryList: string[] = [
    'Afghanistan',
    'Albania',
    'Algeria',
    'American Samoa',
    'Andorra',
    'Angola',
    'Anguilla',
    'Antarctica',
    'Antigua and Barbuda',
    'Argentina',
    'Armenia',
    'Aruba',
    'Australia',
    'Austria',
    'Azerbaijan',
    'Bahamas',
    'Bahrain',
    'Bangladesh',
    'Barbados',
    'Belarus',
    'Belgium',
    'Belize',
    'Benin',
    'Bermuda',
    'Bhutan',
    'Bolivia',
    'Bonaire, Sint Eustatius and Saba',
    'Bosnia and Herzegovina',
    'Botswana',
    'Bouvet Island',
    'Brazil',
    'British Indian Ocean Territory',
    'Brunei Darussalam',
    'Bulgaria',
    'Burkina Faso',
    'Burundi',
    'Cabo Verde',
    'Cambodia',
    'Cameroon',
    'Canada',
    'Cayman Islands',
    'Central African Republic',
    'Chad',
    'Chile',
    'China',
    'Christmas Island',
    'Cocos (Keeling) Islands',
    'Colombia',
    'Comoros (the)',
    'Democratic Republic of Congo',
    'Congo (the)',
    'Cook Islands',
    'Costa Rica',
    'Croatia',
    'Cuba',
    'Curaçao',
    'Cyprus',
    'Czechia',
    'Côte d\'Ivoire',
    'Denmark',
    'Djibouti',
    'Dominica',
    'Dominican Republic',
    'Ecuador',
    'Egypt',
    'El Salvador',
    'Equatorial Guinea',
    'Eritrea',
    'Estonia',
    'Eswatini',
    'Ethiopia',
    'Falkland Islands (the) [Malvinas]',
    'Faroe Islands',
    'Fiji',
    'Finland',
    'France',
    'French Guiana',
    'French Polynesia',
    'French Southern Territories',
    'Gabon',
    'Gambia',
    'Georgia',
    'Germany',
    'Ghana',
    'Gibraltar',
    'Greece',
    'Greenland',
    'Grenada',
    'Guadeloupe',
    'Guam',
    'Guatemala',
    'Guernsey',
    'Guinea',
    'Guinea-Bissau',
    'Guyana',
    'Haiti',
    'Heard Island and McDonald Islands',
    'Holy See (the)',
    'Honduras',
    'Hong Kong',
    'Hungary',
    'Iceland',
    'India',
    'Indonesia',
    'Iran',
    'Iraq',
    'Ireland',
    'Isle of Man',
    'Israel',
    'Italy',
    'Jamaica',
    'Japan',
    'Jersey',
    'Jordan',
    'Kazakhstan',
    'Kenya',
    'Kiribati',
    'Korea (the Democratic People\'s Republic of)',
    'Korea (the Republic of)',
    'Kuwait',
    'Kyrgyzstan',
    'Lao People\'s Democratic Republic (the)',
    'Latvia',
    'Lebanon',
    'Lesotho',
    'Liberia',
    'Libya',
    'Liechtenstein',
    'Lithuania',
    'Luxembourg',
    'Macao',
    'Madagascar',
    'Malawi',
    'Malaysia',
    'Maldives',
    'Mali',
    'Malta',
    'Marshall Islands (the)',
    'Martinique',
    'Mauritania',
    'Mauritius',
    'Mayotte',
    'Mexico',
    'Micronesia (Federated States of)',
    'Moldova (the Republic of)',
    'Monaco',
    'Mongolia',
    'Montenegro',
    'Montserrat',
    'Morocco',
    'Mozambique',
    'Myanmar',
    'Namibia',
    'Nauru',
    'Nepal',
    'Netherlands (the)',
    'New Caledonia',
    'New Zealand',
    'Nicaragua',
    'Niger (the)',
    'Nigeria',
    'Niue',
    'Norfolk Island',
    'Northern Mariana Islands (the)',
    'Norway',
    'Oman',
    'Pakistan',
    'Palau',
    'Palestine, State of',
    'Panama',
    'Papua New Guinea',
    'Paraguay',
    'Peru',
    'Philippines (the)',
    'Pitcairn',
    'Poland',
    'Portugal',
    'Puerto Rico',
    'Qatar',
    'Republic of North Macedonia',
    'Romania',
    'Russian Federation (the)',
    'Rwanda',
    'Réunion',
    'Saint Barthélemy',
    'Saint Helena, Ascension and Tristan da Cunha',
    'Saint Kitts and Nevis',
    'Saint Lucia',
    'Saint Martin (French part)',
    'Saint Pierre and Miquelon',
    'Saint Vincent and the Grenadines',
    'Samoa',
    'San Marino',
    'Sao Tome and Principe',
    'Saudi Arabia',
    'Senegal',
    'Serbia',
    'Seychelles',
    'Sierra Leone',
    'Singapore',
    'Sint Maarten (Dutch part)',
    'Slovakia',
    'Slovenia',
    'Solomon Islands',
    'Somalia',
    'South Africa',
    'South Georgia and the South Sandwich Islands',
    'South Sudan',
    'Spain',
    'Sri Lanka',
    'Sudan (the)',
    'Suriname',
    'Svalbard and Jan Mayen',
    'Sweden',
    'Switzerland',
    'Syrian Arab Republic',
    'Taiwan (Province of China)',
    'Tajikistan',
    'Tanzania, United Republic of',
    'Thailand',
    'Timor-Leste',
    'Togo',
    'Tokelau',
    'Tonga',
    'Trinidad and Tobago',
    'Tunisia',
    'Turkey',
    'Turkmenistan',
    'Turks and Caicos Islands',
    'Tuvalu',
    'Uganda',
    'Ukraine',
    'United Arab Emirates',
    'United Kingdom',
    'United States Minor Outlying Islands',
    'United States of America',
    'Uruguay',
    'Uzbekistan',
    'Vanuatu',
    'Venezuela',
    'Viet Nam',
    'British Virgin Islands',
    'U.S. Virgin Islands',
    'Wallis and Futuna',
    'Western Sahara',
    'Yemen',
    'Zambia',
    'Zimbabwe',
    'Åland Islands'
  ]

  languageList: string[] = [

    'Amharic',
    'English',
    'French',
    'Hindi',
    'Portugese',
    'Spanish',
    'Swahili',

  ]


  birthYear: number[] = []

  birthMonth: number[] = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12
  ]

  birthDay: number[] = [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31
  ]


  constructor(
    private userService: UserDataService,
    private auth: AuthService,
    private router: Router,
    private datePipe: DatePipe,
    private store: Store<{ auth: {authStatus: AuthStateEnum}, userState: {user: UserModel} }>
  ) {


    this.user = new UserModel()
    this.getYear()

  }

  ngOnInit() {


    this.signupForm = new FormGroup({

      fName: new FormControl(null, [Validators.required, Validators.maxLength(35)]),
      lName: new FormControl(null, [Validators.required, Validators.maxLength(35)]),
      userName: new FormControl(null, Validators.required),
      displayName: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      confirmPassword: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      country: new FormControl(null, [Validators.required]),
      language: new FormControl(null),
      // birthDay: new FormControl(null),
      // birthMonth: new FormControl(null),
      // birthYear: new FormControl(null),
      birthDate: new FormControl(null, [Validators.required])

    })
  }


  async submit() {


    console.log(this.signupForm)

    if (this.signupForm.valid) {

      this.user.firstName = this.signupForm.get('fName').value
      this.user.lastName = this.signupForm.get('lName').value
      this.user.username = this.signupForm.get('userName').value
      this.user.email = this.signupForm.get('email').value
      this.user.displayName = this.signupForm.get('displayName').value
      this.user.password = this.signupForm.get('password').value
      this.user.dateOfBirth = new Date(this.signupForm.get('birthDate').value)
      this.user.country = this.signupForm.get('country').value



      const bDate = this.datePipe.transform(this.user.dateOfBirth, 'dd/mm/yyyy')

      this.user.language = 'English'

      console.log(bDate)
      this.result = await this.auth.signUp(this.user.username,  this.user.password, this.user.email, this.user.lastName,
        this.user.firstName, this.user.displayName, '', bDate, this.user.language, this.user.country)

      if (this.result === 'success') {

          // this.auth.userObject.next(this.user)

        console.log('Successful signup.  Navigating to confirmation page.')
        this.router.navigate(['/confirm'])

      } else {



      }
    }



  }

  getYear() {
    const currentYear = new Date().getFullYear()


    let startYear = 1910
    for (let i = startYear; i <= currentYear; i++) {
      this.birthYear.push(startYear++)
    }
  }


}
