<main class="course-container">


  <section class="course-header-container">

    <div class="header-title-container">


      <div fxLayout="row">

        <h1> {{ title }} </h1>
        <p>Instructors: </p>
        <p>Subjects: </p>
      </div>

    </div>

    <div class="header-intro-video-container">

      <video [src]="introVidURL"></video>

    </div>

    <div class="header-info-container">



      <div fxLayout="row">

        <p>Level: </p>
        <p>Language: </p>
        <p>Reviews: </p>

      </div>


    </div>

    <div class="header-action-container">


    </div>


  </section>
  <section class="course-tab-content-container">


    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <em>Course Info</em>
        </ng-template>
        <course-info></course-info>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <!--              <mat-icon>thumb_down</mat-icon> The worst sushi-->
          <em>Lessons</em>
        </ng-template>
        <course-content></course-content>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <!--              <mat-icon>thumb_down</mat-icon> The worst sushi-->
          <em>Feed</em>
        </ng-template>
        <course-feed></course-feed>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <!--              <mat-icon>thumb_down</mat-icon> The worst sushi-->
          <em>Reviews</em>
        </ng-template>
        <course-reviews></course-reviews>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <!--              <mat-icon>thumb_down</mat-icon> The worst sushi-->
          <em>Students</em>
        </ng-template>
        <course-students></course-students>
      </mat-tab>
    </mat-tab-group>

  </section>


</main>



<!--<div class="container">-->

<!--  <div class="row">-->

<!--    <div class="col col-md-3">-->
<!--      &lt;!&ndash;        <video [src]="introVidURL"></video>&ndash;&gt;-->


<!--      <img [src]="coursePosterUrl" class="image"/>-->


<!--    </div>-->

<!--    <div class="col col-md-7">-->


<!--      <h1> {{ title }} </h1>-->
<!--      <p>Instructors: </p>-->
<!--      <p>Subjects: </p>-->

<!--      <p>Level: </p>-->
<!--      <p>Language: </p>-->
<!--      <p></p>-->
<!--    </div>-->

<!--    <div class="col col-md-2">-->




<!--      <div>-->

<!--        <button mat-raised-button color="primary">View Dashboard</button>-->
<!--      </div>-->

<!--      <div>-->

<!--        <button mat-raised-button color="primary">Edit Course</button>-->
<!--      </div>-->

<!--      <div>-->

<!--        <button mat-raised-button color="primary">{{ price }}</button>-->
<!--      </div>-->


<!--    </div>-->



<!--  </div>-->

<!--  <div class="row">-->

<!--    <div class="col">-->
<!--      <mat-tab-group>-->
<!--        <mat-tab>-->
<!--          <ng-template mat-tab-label>-->
<!--            <em>Course Info</em>-->
<!--          </ng-template>-->
<!--          <course-info></course-info>-->
<!--        </mat-tab>-->

<!--        <mat-tab>-->
<!--          <ng-template mat-tab-label>-->
<!--            &lt;!&ndash;              <mat-icon>thumb_down</mat-icon> The worst sushi&ndash;&gt;-->
<!--            <em>Lessons</em>-->
<!--          </ng-template>-->
<!--          <course-content></course-content>-->
<!--        </mat-tab>-->

<!--        <mat-tab>-->
<!--          <ng-template mat-tab-label>-->
<!--            &lt;!&ndash;              <mat-icon>thumb_down</mat-icon> The worst sushi&ndash;&gt;-->
<!--            <em>Feed</em>-->
<!--          </ng-template>-->
<!--          <course-feed></course-feed>-->
<!--        </mat-tab>-->

<!--        <mat-tab>-->
<!--          <ng-template mat-tab-label>-->
<!--            &lt;!&ndash;              <mat-icon>thumb_down</mat-icon> The worst sushi&ndash;&gt;-->
<!--            <em>Reviews</em>-->
<!--          </ng-template>-->
<!--          <course-reviews></course-reviews>-->
<!--        </mat-tab>-->

<!--        <mat-tab>-->
<!--          <ng-template mat-tab-label>-->
<!--            &lt;!&ndash;              <mat-icon>thumb_down</mat-icon> The worst sushi&ndash;&gt;-->
<!--            <em>Students</em>-->
<!--          </ng-template>-->
<!--          <course-students></course-students>-->
<!--        </mat-tab>-->
<!--      </mat-tab-group>-->


<!--    </div>-->

<!--    &lt;!&ndash;      <div class="col col-md-3">&ndash;&gt;-->


<!--    &lt;!&ndash;      </div>&ndash;&gt;-->

<!--  </div>-->


<!--</div>-->










<!--<div class="container">-->

<!--  <div class="row">-->

<!--      <div class="col col-md-3">-->
<!--&lt;!&ndash;        <video [src]="introVidURL"></video>&ndash;&gt;-->


<!--        <img [src]="coursePosterUrl" class="image"/>-->


<!--      </div>-->

<!--      <div class="col col-md-7">-->


<!--         <h1> {{ title }} </h1>-->
<!--        <p>Instructors: </p>-->
<!--        <p>Subjects: </p>-->

<!--        <p>Level: </p>-->
<!--        <p>Language: </p>-->
<!--        <p></p>-->
<!--      </div>-->

<!--      <div class="col col-md-2">-->




<!--        <div>-->

<!--          <button mat-raised-button color="primary">View Dashboard</button>-->
<!--        </div>-->

<!--        <div>-->

<!--          <button mat-raised-button color="primary">Edit Course</button>-->
<!--        </div>-->

<!--        <div>-->

<!--          <button mat-raised-button color="primary">{{ price }}</button>-->
<!--        </div>-->


<!--      </div>-->



<!--  </div>-->

<!--  <div class="row">-->

<!--      <div class="col">-->
<!--        <mat-tab-group>-->
<!--          <mat-tab>-->
<!--            <ng-template mat-tab-label>-->
<!--             <em>Course Info</em>-->
<!--            </ng-template>-->
<!--          <course-info></course-info>-->
<!--          </mat-tab>-->

<!--          <mat-tab>-->
<!--            <ng-template mat-tab-label>-->
<!--&lt;!&ndash;              <mat-icon>thumb_down</mat-icon> The worst sushi&ndash;&gt;-->
<!--              <em>Lessons</em>-->
<!--            </ng-template>-->
<!--         <course-content></course-content>-->
<!--          </mat-tab>-->

<!--          <mat-tab>-->
<!--            <ng-template mat-tab-label>-->
<!--&lt;!&ndash;              <mat-icon>thumb_down</mat-icon> The worst sushi&ndash;&gt;-->
<!--              <em>Feed</em>-->
<!--            </ng-template>-->
<!--            <course-feed></course-feed>-->
<!--          </mat-tab>-->

<!--          <mat-tab>-->
<!--            <ng-template mat-tab-label>-->
<!--&lt;!&ndash;              <mat-icon>thumb_down</mat-icon> The worst sushi&ndash;&gt;-->
<!--              <em>Reviews</em>-->
<!--            </ng-template>-->
<!--          <course-reviews></course-reviews>-->
<!--          </mat-tab>-->

<!--          <mat-tab>-->
<!--            <ng-template mat-tab-label>-->
<!--&lt;!&ndash;              <mat-icon>thumb_down</mat-icon> The worst sushi&ndash;&gt;-->
<!--              <em>Students</em>-->
<!--            </ng-template>-->
<!--           <course-students></course-students>-->
<!--          </mat-tab>-->
<!--        </mat-tab-group>-->


<!--      </div>-->

<!--&lt;!&ndash;      <div class="col col-md-3">&ndash;&gt;-->


<!--&lt;!&ndash;      </div>&ndash;&gt;-->

<!--  </div>-->


<!--</div>-->
