import {Injectable, OnChanges, OnInit} from '@angular/core'
import {BehaviorSubject, Observable} from 'rxjs'
import {AuthStateEnum} from '../Auth/authstate.action'

@Injectable({
  providedIn: 'root'
})
export class LmsService {

  public currentLMSState: boolean

  public isLMS = new BehaviorSubject<boolean>(false)
  public onLMS: Observable<boolean> = this.isLMS.asObservable()


  constructor() {




    this.onLMS.subscribe( status => {


      this.currentLMSState = status

    })

  }


  updateLMS(lmsCurrent: boolean){

      this.isLMS.next(lmsCurrent)
  }


}
