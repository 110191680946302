<div class="container">

  <div class="row">
    <div class="col">


    </div>


  </div>

  <div class="row">

    <div class="col-sm col-lg-8">
      <course-lesson></course-lesson>

    </div>
    <div class="col-lg-4">



    </div>


  </div>



</div>
