<mat-card class="card">
  <div class="form-container">

    <h2>Confirmation Code</h2>

    <form [formGroup]="confirmForm" (ngSubmit)="confirm()">
      <div class="form-group">


        <mat-form-field class="form-field" appearance="outline">
          <mat-label>Confirmation Code</mat-label>
          <input matInput placeholder="Confirmation Code" class="form-control" formControlName="code" id="code">
          <mat-hint>Enter the Confirmation code sent to your email.</mat-hint>
        </mat-form-field>



<!--        <button mat-raised-button color="primary" class="button btn-primary" [disabled]="!confirmForm.valid">Login</button>-->

        <span>
          <button mat-button (click)="confirm()">Verify</button>
        </span>



      </div>

    </form>
  </div>

</mat-card>
