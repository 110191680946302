import {Deserializable} from './Deserializable.model';


export class AuthCookieModel implements Deserializable {


  public userId: string

  public username: string

  public authenticatedDate: Date

  public expirationDays: number



  deserialize(input: any): this {
    return undefined;
  }

}
