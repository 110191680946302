
<div class="container">
<mat-card class="card">
  <div class="form-container">

    <h2>Login</h2>

    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <div class="form-group">


        <mat-form-field class="form-field">
          <input matInput type="email" placeholder="Username" class="form-control" formControlName="userName" id="userName">
        </mat-form-field>

        <mat-form-field class="form-field">
          <input matInput type="password" placeholder="Password"  class="form-control" formControlName="password" id="password">
        </mat-form-field>


        <button mat-raised-button color="primary" class="button btn-primary" [disabled]="!loginForm.valid">Login</button>

        <span>
          <mat-label>Create Account: </mat-label>
          <button mat-button (click)="goToSignup()">Signup</button>
        </span>




      </div>

    </form>
  </div>

</mat-card>
</div>
