import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-library-search-results',
  templateUrl: './library-search-results.component.html',
  styleUrls: ['./library-search-results.component.scss']
})
export class LibrarySearchResultsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
