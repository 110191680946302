import {CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AuthService} from './services/Auth/auth.service';
import {stat} from 'fs';


@Injectable()
export class AuthGuardService implements CanActivate {

  isAuthenticated: boolean;

  constructor(private auth: AuthService, private router: Router) {


      this.auth.authenticated.subscribe((state) => {


        this.isAuthenticated = state;

      });


  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean | Promise<boolean> {




    if (this.isAuthenticated){

      return true
    }else {

      this.router.navigate(['/'])

    }


    // return this.auth.authenticated.toPromise().then((authenthicated: boolean) =>
    // {
    //
    //
    //
    //   // if(authenthicated){
    //   //
    //   // return true;
    //   // } else {
    //   //
    //   //   this.router.navigate(['/']);
    //   // }
    //
    //
    // });

  }

}
